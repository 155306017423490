import type { AsyncPlaneThunkState, PlaneId } from '../types';
import CloudStorageClient from '../../../../../clients/cloud-storage';
import ModuleClient from '../../../../../clients/module';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { removeRelayParties } from '../../relays/remove-relay-parties/thunk';

export const closePlane = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/closePlane',
  async (planeId: PlaneId, { dispatch, getState }) => {
    const storePlane = getState().planes[planeId];
    if (!storePlane) {
      return;
    }

    await ModuleClient.Events.close(storePlane);

    // @ts-expect-error - We need to fix our types
    dispatch(removeRelayParties(planeId));

    if (storePlane.cloudStorageId) {
      await CloudStorageClient.closeProject(storePlane.cloudStorageId);
    }
  },
);
