import type {
  CreateOptions,
  CreatePlaneOptions,
  CreateResult,
  InvokeByModuleKeyOptions,
  InvokeByOptionsOptions,
  OpenOptions,
  OpenOptionsV2,
  OpenResult,
  PromptAndInvokeOptions,
  TargetPlane,
} from './types';
import type PlaneChannel from '../../../clients/channel/plane-channel';
import type { DataItem } from '../../../clients/data/types';
import type { ModuleConfig, ModuleConfigKey } from '../../../modules/types';
import type { PlaneId } from '../../store/reducers/planes/types';
import type { NestedPlaneState, Plane } from '../../types';
import type { CloudStoragePassthroughItem, InterceptedOpenState } from '@mtb/cloud-storage/types';
import { CloudStorageError } from '@mtb/cloud-storage';
import { deepMerge } from '@mtb/utilities';
import BrowserHistoryClient from '../../../clients/browser-history';
import CloudStorageClient from '../../../clients/cloud-storage';
import DataClient from '../../../clients/data';
import DialogsClient from '../../../clients/dialogs';
import I18nClient from '../../../clients/i18n';
import LoggerClient from '../../../clients/logger';
import ModuleClient from '../../../clients/module';
import config from '../../../config';
import { ROOT_MODULE_KEY } from '../../../constants';
import MODULES from '../../../modules';
import platformStore from '../../store';
import { PLANE_HEALTH_TYPES, PLANE_STATUS_TYPES, PLANE_VARIANTS } from '../../store/reducers/planes/constants';
import { PlaneError } from '../../store/reducers/planes/error';
import { getModule, getModuleOptions, loadPlaneProject, openPassthroughData, toPlane } from './utils';
import { verifyAddPassthroughToPlane, verifyBeforeCreate, verifyBeforeOpen, verifyMaxPlanes } from './verify';

class PlaneService {
  #logger = LoggerClient.createNamedLogger('PlaneService');

  /**
   * The different handlers for creating and opening planes related to each module.
   */
  #moduleHandlerMap = {
    [MODULES.DATACENTER.key]: {
      create: this.createDataPrep.bind(this),
      open  : this.openDataPrep.bind(this),
    },
    [MODULES.WSO.key]: {
      create: this.createWorkspace.bind(this),
      open  : this.openWorkspace.bind(this),
    },
    [MODULES.BRAINSTORM.key]: {
      create: this.createBrainstorm.bind(this),
      open  : this.openBrainstorm.bind(this),
    },
    [MODULES.DASHBOARD.key]: {
      create: this.createDashboard.bind(this),
      open  : this.openDashboard.bind(this),
    },
    [MODULES.MSSO.key]: {
      create: this.createAnalysis.bind(this),
      open  : this.openAnalysis.bind(this),
    },
  };

  /**
   * Gets the formatted parts of the plane name.
   * @param module - The name to format.
   * @param name - The name to get the parts from.
   * @returns The parts of the plane name.
   */
  #getPlaneNameParts(module: ModuleConfig, name?: string) {
    // @ts-expect-error - Ignore until we fix the Module Config types.
    const defaultName = I18nClient.t(module.storage.defaultProjectName);
    const nameParts = CloudStorageClient.getNameParts(name || defaultName);
    if (!nameParts.displayName) {
      throw new Error('Failed to get plane name parts.');
    }
    return {
      ...nameParts,
      extension: Boolean(nameParts.extension)
        ? nameParts.extension
        : module.storage?.defaultExtension?.startsWith('.')
          ? module.storage?.defaultExtension.slice(1)
          : module.storage?.defaultExtension,
    };
  }

  /**
   * Creates a new plane with the given options.
   * @param module - The module to create the plane with.
   * @param options - The options to create the plane with.
   * @returns The created plane.
   */
  #createPlane(module: ModuleConfig, options: CreatePlaneOptions) {
    try {
      const { displayName, extension } = this.#getPlaneNameParts(module, options.name);
      const defaultState = module.storage?.defaultPlaneState || {};
      const optionsState = options.state || {};
      const state = deepMerge(defaultState, optionsState) as NestedPlaneState;
      const { payload: plane } = platformStore.actions.createPlane({
        module   : module.key,
        icon     : module.icon,
        name     : displayName,
        extension: `.${extension}`,
        variant  : options.variant || PLANE_VARIANTS.PERSISTENT,
        state,
      });
      if (!options.preventNavigation) {
        platformStore.actions.setCurrentLayout({
          currentModuleKey: plane.module,
          currentPlaneId  : plane.id,
        });
        BrowserHistoryClient.updateBrowserHistory(
          {
            currentModuleKey: plane.module,
            currentPlaneId  : plane.id,
          },
          { replace: false },
        );
      }
      return plane;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  async #createProjectForPlane(plane: Plane, data: DataItem) {
    try {
      const project = await data.createProject(`${plane.name}${plane.extension}`);
      platformStore.actions.updatePlane(plane.id, {
        // Ensure we update the name in case it was changed during project creation.
        name          : project.displayName,
        // Set the project ID to the plane to associate the plane with the cloud storage project.
        cloudStorageId: project.id,
      });
    } catch (error) {
      let planeError: PlaneError;
      if (error instanceof CloudStorageError) {
        planeError = PlaneError.FromCloudStorageError(error);
      } else {
        const err = error instanceof Error ? error : new Error('Failed to create a project for the plane.');
        planeError = PlaneError.FromUnknownError(err);
      }
      platformStore.actions.setPlaneError(plane.id, planeError);
      this.#logger.error(error);
    }
  }

  /**
   * Adds the passthrough item to the given plane either by updating the plane's state,
   * or by opening the passthrough item if the plane is currently open.
   * @param plane - The plane to add the passthrough item to.
   * @param data - The data item to add to the plane.
   * @returns The plane with the passthrough item added.
   */
  async #addPassthroughToPlane(plane: Plane, data: DataItem): Promise<CloudStoragePassthroughItem> {
    verifyAddPassthroughToPlane(plane, data);

    const passthroughItem = await data.createPassthrough();
    // TODO: Remove this once we go live with the V2 Work.
    // Until we go live we need a way to instruct MSSO when to use v2 functionality
    // in their onOpen event. This can be removed once V2 goes live and
    // we can remove this and th{e V1 on open code from MSSO onOpen handler.
    if (plane.module === MODULES.MSSO.key) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Ignore this temporary property.
      passthroughItem.onOpenV2 = true;
    }
    // If a plane isn't currently open or the current plane id is different the given plane id,
    // we need to add the passthrough item to the target plane's state. This will allow
    // the plane to load the passthrough item the next time the plane is opened.
    const currentPlane = this.getCurrentPlane();
    const isCurrentPlaneActive = currentPlane && currentPlane?.id === plane.id;
    if (!isCurrentPlaneActive) {
      const planeState = deepMerge(plane.state, {
        passthroughItem,
      }) as NestedPlaneState;
      platformStore.actions.updatePlane(plane.id, { state: planeState });
      return passthroughItem;
    }

    return passthroughItem;
  }

  constructor() {
    if (process.env.NODE_ENV === 'test') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Expose some internal methods for spying purposes in tests.
      this.moduleHandlerMap = this.#moduleHandlerMap;
    }

    window.addEventListener('beforeunload', () => {
      platformStore.actions.cleanupPlanes();
    });
  }

  getCurrentPlane() {
    return platformStore.selectors.currentPlane(platformStore.getState());
  }

  /**
   * Gets the plane with the given planeId from the store.
   */
  getPlaneById(planeId?: PlaneId): Plane | undefined {
    return planeId ? platformStore.selectors.plane(platformStore.getState(), planeId) : undefined;
  }

  /**
   * Gets the planes from the store sorted by created date in the given order.
   * @param order - The sorting order, either 'asc' (ascending) or 'desc' (descending). Default is 'desc'.
   */
  getPlanesByCreatedDate(order: 'asc' | 'desc' = 'desc'): Plane[] {
    return platformStore.selectors.planesByCreatedDate(platformStore.getState(), order);
  }

  /**
   * Updates the plane with the given planeId with the given plane properties.
   * @param planeId
   * @param planeProps
   */
  update(planeId: PlaneId, planeProps: Partial<Plane>) {
    platformStore.actions.updatePlane(planeId, planeProps);
  }

  /**
   * Updates the plane with the given planeId with the given name and optional extension.
   * @param planeId
   * @param options
   */
  async rename(id: string, options: { name: string; extension?: string }): Promise<void> {
    const plane = this.getPlaneById(id);
    if (!plane) {
      throw new Error('No plane found with the given id.');
    }
    if (plane.cloudStorageId) {
      throw new Error('Changing the name of a plane with a cloud storage project is not supported.');
    }
    let extension = options.extension;
    if (extension?.startsWith?.('.')) {
      extension = extension.slice(1);
    }
    this.update(id, { name: options.name, ...(extension ? { extension: extension.toLowerCase() } : {}) });
  }

  /**
   * Determines whether we should confirm closing the plane with the given planeId.
   * @param planeId - The planeId to verify.
   */
  shouldConfirmClose(planeId: PlaneId) {
    const state = platformStore.getState();
    const plane = platformStore.selectors.plane(state, planeId);
    if (!plane) {
      return false;
    }
    if (plane.dirty) {
      return true;
    }
    if (!plane.cloudStorageId) {
      return false;
    }
    const { isHealthy } = platformStore.selectors.planeHealth(state, planeId);
    const isUnsavedProject = CloudStorageClient.isUnsavedProject(plane.cloudStorageId);
    return isUnsavedProject && isHealthy;
  }

  /**
   * Prompts the user to confirm closing the plane with the given planeId.
   * @param planeId - The planeId to confirm closing.
   * @returns True if the user confirms closing the plane, otherwise false.
   */
  async confirmClose(planeId: PlaneId) {
    const plane = this.getPlaneById(planeId);
    const module = ModuleClient[plane?.module as ModuleConfigKey];
    const unsavedChangesPrompt = module?.prompts?.unsavedChanges;
    const title = unsavedChangesPrompt?.title ? I18nClient.t(unsavedChangesPrompt.title) : undefined;
    const message = unsavedChangesPrompt?.message ? I18nClient.t(unsavedChangesPrompt.message) : undefined;
    const confirm = unsavedChangesPrompt?.actions?.confirm
      ? I18nClient.t(unsavedChangesPrompt.actions.confirm)
      : undefined;
    const cancel = unsavedChangesPrompt?.actions?.cancel
      ? I18nClient.t(unsavedChangesPrompt.actions.cancel)
      : undefined;

    const canClose = await DialogsClient.showConfirmCloseDialog({
      title,
      message,
      confirm,
      cancel,
    });
    return canClose;
  }

  /**
   * Closes the plane with the given planeId.
   * @param planeId - The planeId to close.
   * @returns Whether the plane was closed successfully.
   */
  async closePlane(planeId: PlaneId): Promise<void> {
    const canClose = this.shouldConfirmClose(planeId) ? await this.confirmClose(planeId) : true;
    if (!canClose) {
      return;
    }

    const currentPlaneId = platformStore.selectors.currentPlaneId(platformStore.getState());
    if (planeId === currentPlaneId) {
      const nextPlane = this.getPlanesByCreatedDate().find(plane => plane.id !== planeId);
      if (nextPlane) {
        platformStore.actions.setPlaneLoading(nextPlane.id, true);
        platformStore.actions.setCurrentLayout({
          currentModuleKey: nextPlane.module,
          currentPlaneId  : nextPlane.id,
        });
        BrowserHistoryClient.updateBrowserHistory({
          currentModuleKey: nextPlane.module,
          currentPlaneId  : nextPlane.id,
        });
      } else {
        const nextModuleKey = ROOT_MODULE_KEY;
        platformStore.actions.setCurrentLayout({
          currentModuleKey: nextModuleKey,
          currentPlaneId  : undefined,
        });
        BrowserHistoryClient.updateBrowserHistory({
          currentModuleKey: nextModuleKey,
          currentPlaneId  : undefined,
        });
      }
    }
    await platformStore.actions.closePlane(planeId);
  }

  recover(id: string): void {
    this.update(id, { health: PLANE_HEALTH_TYPES.RECOVERABLE });
  }

  /**
   * Verifies that the maximum number of planes has not been reached.
   * @deprecated - Remove once the client isn't using this anymore.
   */
  verifyMaxPlanes() {
    verifyMaxPlanes();
  }

  /**
   * Creates a new plane with the given options.
   * TODO: Add support to show a dialog when the process of opening the analysis takes
   * longer than expected threshold.
   * @param module - The module to create the plane with.
   * @param options - The options to create the plane with.
   * @returns - The created plane.
   */
  async invokeCreate(module: ModuleConfig, options: CreateOptions): CreateResult {
    try {
      verifyMaxPlanes();
      verifyBeforeCreate(module, options);

      const emptyFile = new File([], options.name || '');
      const data = options.data || (await DataClient.create(emptyFile));
      const plane = this.#createPlane(module, {
        name   : options.name || data.name,
        state  : options.state || {},
        variant: options.variant || PLANE_VARIANTS.PERSISTENT,
      });
      await options.onBeforeCreate?.(data);
      if (!options.noProject) {
        await this.#createProjectForPlane(plane, data);
      }
      // Put the plane in the default loading state to signify that the remote module
      // of the plane can start the process of loading.
      this.update(plane.id, { status: PLANE_STATUS_TYPES.LOADING });
      return plane.id;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Gets or creates the plane with the given module and options.
   * @param module
   * @param options
   * @returns
   */
  async #getOrCreatePlane(module: ModuleConfig, options: OpenOptions): Promise<Plane> {
    try {
      const plane = this.getPlaneById(options.planeId);
      if (plane) {
        return plane;
      }
      // Look up the handler for the module and invoke the create action.
      // This will ensure we go through the flow of creating a new plane with
      // the expected default state and properties.
      const createHandler = this.#moduleHandlerMap[module.key].create;
      const createdPlaneId = await createHandler({
        ...(options.preventNavigation && { preventNavigation: options.preventNavigation }),
        name : options.name,
        state: options.state || {},
      });
      const createdPlane = this.getPlaneById(createdPlaneId);
      if (!createdPlane) {
        throw new Error('Failed to get or create plane.');
      }
      return createdPlane;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Opens a plane with the given options.
   * TODO: Add support to show a dialog when the process of opening the analysis takes
   * longer than expected threshold.
   * @param module - The module to open the plane with.
   * @param options - The options to open the plane with.
   * @returns The opened plane.
   */
  async invokeOpen(module: ModuleConfig, options: OpenOptions): OpenResult {
    try {
      verifyBeforeOpen(module, options);

      const plane = await this.#getOrCreatePlane(module, options);

      await options.onBeforeOpen?.(options.data);

      // If enableOpenData is false we'll use the deprecated passthrough item behavior.
      // TODO: Remove this and the option once everyone has opted into using the open data behavior.
      let passthroughItem = {} as CloudStoragePassthroughItem;
      if (!options.enableOpenData) {
        passthroughItem = await this.#addPassthroughToPlane(plane, options.data);
      }

      if (!plane.cloudStorageId) {
        this.update(plane.id, { name: options.data.name, extension: options.data.extension });
      }

      const updatedPlane = platformStore.getState().planes[plane.id];
      await ModuleClient.Events.open(updatedPlane, { ...passthroughItem, data: options.data, extra: options.extra });

      return updatedPlane.id;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Creates a new MSSO analysis plane with the given options.
   * TODO: Add support to show a dialog when the process of opening the analysis takes
   * longer than expected threshold.
   * @param options - The options to create the analysis with.
   * @returns The created analysis plane.
   */
  async createAnalysis(options: CreateOptions = {}): CreateResult {
    // Mark an analysis as new if it's not being created with a data item or file.
    const initialState = { isNew: Boolean(!options.data) };
    return await this.invokeCreate(MODULES.MSSO, {
      ...options,
      state: {
        ...initialState,
        ...(options.state || {}),
      },
    });
  }

  /**
   * Opens an analysis plane with the given options.
   * @param options - The options to open the analysis with.
   * @returns The opened analysis plane.
   */
  async openAnalysis(options: OpenOptions): OpenResult {
    if (config.feature_flag_dc_preview && !options.skipDataPreview && this.#dataIsPreviewable(options.data)) {
      await this.openDataPreview(options, {
        module: MODULES.MSSO.key,
        id    : options.planeId,
      });
      return {} as OpenResult;
    }
    return await this.invokeOpen(MODULES.MSSO, options);
  }

  /**
   * Creates a new workspace plane with the given options.
   * @param {CreateOptions} [options={}] - The options to create the workspace with.
   * @returns {CreateResult} The created workspace plane.
   */
  async createWorkspace(options: CreateOptions = {}): CreateResult {
    const module = MODULES.WSO;
    const defaultExtension = module.storage?.defaultExtension || '';
    if (['qcpx', 'qctx'].includes(options?.data?.extension ?? '') && (options?.data?.size ?? 0) > 419430400) {
      DialogsClient.showAlertDialog({
        title   : I18nClient.t('wsoCannotUploadFileTitle'),
        message : I18nClient.t('wsoCannotUploadFileMessage'),
        severity: 'error',
      });
      return undefined;
    }
    options.onBeforeCreate = async (data: DataItem) => {
      if (data.extension === 'qcpx') {
        const originalName = data.name;
        await data.duplicate();
        await data.rename(`${originalName}${defaultExtension}`);
      }
    };
    return await this.invokeCreate(module, options);
  }

  /**
   * Opens a workspace plane with the given options.
   * @param {OpenOptions} options - The options to open the workspace with.
   * @returns {OpenResult} The opened workspace plane.
   */
  async openWorkspace(options: OpenOptions): OpenResult {
    options.state = { ...options.state, scenario: 'openingPassthrough' };
    if (options.data.extension === 'qctx') {
      options.name = options.data.name;
    }
    return await this.invokeOpen(MODULES.WSO, options);
  }

  /**
   * Creates a new brainstorm plane with the given options.
   * @param {CreateOptions} [options={}] - The options to create the brainstorm with.
   * @returns {CreateResult} The created brainstorm plane.
   */
  async createBrainstorm(options: CreateOptions = {}): CreateResult {
    return await this.invokeCreate(MODULES.BRAINSTORM, options);
  }

  /**
   * Opens a brainstorm plane with the given options.
   * @param {OpenOptions} options - The options to open the brainstorm with.
   * @returns {OpenResult} The opened brainstorm plane.
   */
  async openBrainstorm(options: OpenOptions): OpenResult {
    return await this.invokeOpen(MODULES.BRAINSTORM, options);
  }

  async openDataPreview(options: OpenOptions, targetPlane: TargetPlane): Promise<void> {
    const { data } = options;

    // Ensure the remote module's content is loaded. Since we aren't using
    // routing Data Center's remote module content is optimistically fetched
    ModuleClient.DATACENTER.preloadRemoteModule?.();

    const initialState = {
      isNew    : false,
      isPreview: true,
      targetPlane,
    };

    const plane = this.#createPlane(MODULES.DATACENTER, {
      name             : data.name,
      state            : initialState,
      variant          : PLANE_VARIANTS.TEMPORARY,
      preventNavigation: true,
    });

    // Put the plane in the default loading state to signify that the remote module
    // of the plane can start the process of loading.
    this.update(plane.id, { status: PLANE_STATUS_TYPES.LOADING });

    const dialog = DialogsClient.showPlaneDialog({ planeId: plane.id });

    await this.invokeOpen(MODULES.DATACENTER, {
      planeId: plane.id,
      data,
    });

    await dialog;
  }

  /**
   * Creates a new data plane with the given options.
   * @param {CreateOptions} [options={}] - The options to create the data plane with.
   * @returns {CreateResult} The created data plane.
   */
  async createDataPrep(options: CreateOptions = {}): CreateResult {
    // Mark data prep as new if it's not being created with data.
    const initialState = { isNew: Boolean(!options.data) };
    const createOptions = {
      ...options,
      noProject     : true,
      enableOpenData: true,
      state         : {
        ...initialState,
        ...(options.state || {}),
      },
    };
    return await this.invokeCreate(MODULES.DATACENTER, createOptions);
  }

  /**
   * Opens a data plane with the given options.
   * @param {OpenOptions} options - The options to open the data plane with.
   * @returns {OpenResult} The opened data plane.
   */
  async openDataPrep(options: OpenOptions): OpenResult {
    // Mark data prep as new if it's not being created with data.
    const initialState = { isNew: Boolean(!options.data) };
    const openOptions = {
      ...options,
      noProject     : true,
      enableOpenData: true,
      state         : {
        ...initialState,
        ...(options.state || {}),
      },
    };
    return await this.invokeOpen(MODULES.DATACENTER, openOptions);
  }

  /**
   * Creates a new dashboard plane with the given options.
   * @param {CreateOptions} [options={}] - The options to create the dashboard plane with.
   * @returns {CreateResult} The created dashboard plane.
   */
  async createDashboard(options: CreateOptions = {}): CreateResult {
    // Mark dashboard as new if it's not being created with data.
    const initialState = { isNew: Boolean(!options.data) };
    return await this.invokeCreate(MODULES.DASHBOARD, {
      ...options,
      state: {
        ...initialState,
        ...(options.state || {}),
      },
    });
  }

  /**
   * Opens a report plane with the given options.
   * @param {OpenOptions} options - The options to open the report plane with.
   * @returns {OpenResult} The opened report plane.
   */
  async openDashboard(options: OpenOptions): OpenResult {
    if (config.feature_flag_dc_preview && !options.skipDataPreview && this.#dataIsPreviewable(options.data)) {
      await this.openDataPreview(options, {
        module: MODULES.DASHBOARD.key,
        id    : options.planeId,
      });
      return {} as OpenResult;
    }
    // Mark dashboard as new if it's not being created with data.
    const initialState = { isNew: Boolean(!options.data) };
    return await this.invokeOpen(MODULES.DASHBOARD, {
      ...options,
      state: {
        ...initialState,
        ...(options.state || {}),
      },
    });
  }

  /**
   * Opens an channel to another plane by ID
   * @param planeId - The ID of the plane to open a channel with
   * @param channel - The PlaneChannel to pass
   * @returns Whether the plane exists.
   */
  openChannel(planeId: string, channel: PlaneChannel): boolean {
    const plane = this.getPlaneById(planeId);
    if (plane) {
      ModuleClient.Events.openChannel(plane, channel);
      return true;
    }
    return false;
  }

  /**
   * Creates a new plane using the given module's create action.
   * @param moduleKey - The key of the module to create the plane with.
   * @param options - The options to create the plane with.
   * @returns The created plane.
   */
  async invokeByModuleKey(
    moduleKey: ModuleConfigKey,
    options: InvokeByModuleKeyOptions,
  ): Promise<PlaneId | undefined> {
    const user = platformStore.selectors.user(platformStore.getState());
    const module = ModuleClient.getEnabledModule(moduleKey, user);
    if (!module) {
      throw new Error(`Unable to invoke by module key: unsupported module key: ${moduleKey}`);
    }

    const actionModule = this.#moduleHandlerMap[module.key];
    if (!actionModule) {
      throw new Error(`Unable to invoke by module key: could not find actions for module key: ${module.key}`);
    }

    const data = options.data;
    const handlerType = module?.storage?.passthroughFilter?.includes(`.${data.extension}`) ? 'open' : 'create';
    const handler = this.#moduleHandlerMap[module.key][handlerType];
    if (!handler) {
      throw new Error(`Unable to invoke by module key: unsupported handler type: ${handlerType}`);
    }

    const planeId = await handler(options);
    return planeId;
  }

  /**
   * Prompts the user to open the given data item in a specific module and invokes the create action.
   * @param options - The options to prompt the user with.
   * @returns The created plane.
   */
  async showOpenInDialog(options: PromptAndInvokeOptions) {
    try {
      const result = await DialogsClient.showOpenInDialog({ item: options.data });
      if (!result) {
        return;
      }

      const user = platformStore.selectors.user(platformStore.getState());
      const module = ModuleClient.getEnabledModule(result.moduleKey, user);
      if (!module) {
        throw new Error('Failed to open from intercepted state: module could not be found.');
      }

      return await this.invokeByModuleKey(result.moduleKey, options);
    } catch (error) {
      this.#logger.error('Failed to prompt open in and invoke.', error);
      throw error;
    }
  }

  /**
   * Creates or opens a plane using the given options.
   * @param options - The options to create or open the plane with.
   * @returns The created or opened plane.
   */
  async invokeByOptions(options: InvokeByOptionsOptions): Promise<PlaneId | undefined> {
    try {
      const data = options.data;
      const user = platformStore.selectors.user(platformStore.getState());
      if (options.preventDefault) {
        const modules = ModuleClient.getModulesByExtension(data.extension, user);
        if (!modules.length) {
          throw new Error('No modules found for the given extension.');
        }

        return modules.length === 1
          ? await this.invokeByModuleKey(modules[0].key, options)
          : await this.showOpenInDialog(options);
      }

      const defaultModule = ModuleClient.getDefaultModuleByExtension(data.extension, user);
      if (!defaultModule) {
        throw new Error('No default module found for the given extension.');
      }
      return await this.invokeByModuleKey(defaultModule.key, options);
    } catch (error) {
      this.#logger.error('Failed to invoke by options.', error);
      throw error;
    }
  }

  /**
   * Create a plane from/open's into a plane using the intercepted state object.
   * @param state - The state object found in the query string on page load in the URL.
   * @returns The opened plane.
   */
  async openFromInterceptedState(state: InterceptedOpenState) {
    try {
      verifyMaxPlanes();

      const cloudStorageItem = await CloudStorageClient.getStorageItemFromState(state);
      const data = await DataClient.create(cloudStorageItem);
      const canOpen = data.verifyBeforeOpen();
      if (!canOpen) {
        throw new Error('Failed to open from intercepted state: item could not be opened.');
      }

      if (config.feature_flag_open_api) {
        await this.open({ data });
      } else {
        return await this.invokeByOptions({ data, preventDefault: true });
      }
    } catch (error) {
      this.#logger.error('Failed to open from intercepted state.', error);
      throw error;
    }
  }

  #dataIsPreviewable(data: DataItem) {
    return MODULES.DATACENTER.storage?.passthroughFilter?.includes(`.${data.extension}`) ?? false;
  }

  async open(options: OpenOptionsV2): Promise<{ planeId: string }> {
    try {
      let plane = this.getPlaneById(options.planeId);

      let module = plane
        ? ModuleClient[plane.module]
        : options.moduleKey
          ? ModuleClient[options.moduleKey]
          : undefined;

      if (!module) {
        const moduleSelection = await getModule(options);
        if (!moduleSelection) {
          return { planeId: '' };
        }

        module = moduleSelection;
      }

      module.preloadRemoteModule?.();

      const moduleOptions = getModuleOptions(module, options);

      const [verified, errorMsg] = moduleOptions.verifyData?.(
        moduleOptions.passthroughData ?? moduleOptions.projectData,
      ) ?? [true];
      if (!verified) {
        throw new Error(errorMsg);
      }

      const canOpen = moduleOptions.projectData.verifyBeforeOpen();
      if (!canOpen) {
        throw new Error('Project data could not be opened.');
      }

      const createNewPlane =
        !Boolean(plane) ||
        moduleOptions.showPreview ||
        (moduleOptions.isProjectData && options?.data && config.feature_flag_open_project_files);

      if (createNewPlane) {
        if (moduleOptions.variant !== PLANE_VARIANTS.TEMPORARY) {
          verifyMaxPlanes();
        }

        const { payload } = platformStore.actions.createPlane({
          extension: `.${moduleOptions.extension}`,
          icon     : module.icon,
          module   : moduleOptions.module,
          name     : moduleOptions.name,
          state    : moduleOptions.state,
          variant  : moduleOptions.variant,
        });

        plane = payload;
      }

      if (!plane) {
        throw new Error('Failed to create plane.');
      }

      // ensure plane mounts
      if (moduleOptions.showPreview) {
        DialogsClient.showPlaneDialog({ planeId: plane.id });
      } else if (this.getCurrentPlane()?.id !== plane.id) {
        toPlane(plane);
      }

      if (createNewPlane) {
        if (!moduleOptions.noProject) {
          await loadPlaneProject(plane, moduleOptions);
        }

        // Put the plane in the default loading state to signify that the remote module
        // of the plane can start the process of loading.
        const updatedPlane = this.getPlaneById(plane.id);
        if (updatedPlane?.status === PLANE_STATUS_TYPES.PENDING) {
          this.update(updatedPlane.id, { status: PLANE_STATUS_TYPES.LOADING });
        } else {
          throw new Error('New plane is not in the expected state.');
        }
      }

      if (moduleOptions.passthroughData) {
        await openPassthroughData(plane, moduleOptions);
      }

      return { planeId: plane.id };
    } catch (error) {
      this.#logger.error('Open failed: ', error);
      return { planeId: '' };
    }
  }
}

export default PlaneService;
