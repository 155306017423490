/* eslint-disable @typescript-eslint/no-unused-vars */
import type PlaneChannel from '../../clients/channel/plane-channel';
import type { IntegratedPlatformModule } from '../platform-module/types';
import type { CloudStoragePassthroughItem, CloudStorageProjectItem } from '@mtb/cloud-storage/types';

/**
 * Represents the events configuration for a platform remote module.
 */
class RemoteModuleConfigEvents {
  /**
   * @deprecated This property is no longer used.
   */
  set platformModuleArg(value: boolean) {
    // TODO: Remove this once we no longer have remote modules trying to set this value.
  }

  onPulse = (_platformModule: IntegratedPlatformModule) => Promise.resolve();
  onCleanup = (_platformModule: IntegratedPlatformModule) => Promise.resolve();
  onClose = (_platformModule: IntegratedPlatformModule) => Promise.resolve();
  onFlush = (_platformModule: IntegratedPlatformModule) => Promise.resolve();
  onOpen = (
    _platformModule: IntegratedPlatformModule,
    _fileInfo: CloudStoragePassthroughItem | CloudStorageProjectItem,
  ) => Promise.resolve();
  onOpenChannel = (
    _platformModule: IntegratedPlatformModule,
    _channel: PlaneChannel,
  ) => Promise.resolve();
}

export default RemoteModuleConfigEvents;
