import type { RelaySliceItem, RelaysSlice } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { deepMerge } from '@mtb/utilities';

export const updateRelay = {
  reducer: (state: RelaysSlice, action: PayloadAction<{ id: string; relay: Partial<RelaySliceItem> }>) => {
    if (!state[action.payload.id]) {
      return;
    }
    state[action.payload.id] = deepMerge(state[action.payload.id], action.payload.relay) as RelaySliceItem;
  },
  prepare: (id: string, relay: Partial<RelaySliceItem>) => {
    return { payload: { id, relay } };
  },
};
