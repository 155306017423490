import type {
  ConfirmDialogOptions,
  InitializeDialogsOptions,
  ShowAlertDialogHandler,
  ShowConfirmDialogHandler,
  ShowOpenInDialogHandler,
  ShowPlaneDialogHandler,
  ShowStorageExplorerDialogHandler,
} from './types';
import { createElement } from 'react';
import { Stack, Typography } from '@mtb/ui';
import { MAX_NUMBER_OF_PLANES } from '../../constants';
import I18nClient from '../i18n';
import ModuleClient from '../module';

/**
 * DialogsClient serves as a client to display dialogs in Platform.
 */
class DialogsClient {
  #initialized = false;

  #showOpenInDialog: ShowOpenInDialogHandler = () => Promise.resolve({ moduleKey: ModuleClient.ROOT.key });
  #showStorageExplorerDialog: ShowStorageExplorerDialogHandler = async () => Promise.resolve(undefined);
  #showPlaneDialog: ShowPlaneDialogHandler = async () => Promise.resolve();
  #showAlertDialog: ShowAlertDialogHandler = async () => Promise.resolve(true);
  #showConfirmDialog: ShowConfirmDialogHandler = async () => Promise.resolve(true);

  get showOpenInDialog() {
    return this.#showOpenInDialog;
  }

  get showStorageExplorerDialog() {
    return this.#showStorageExplorerDialog;
  }

  get showPlaneDialog() {
    return this.#showPlaneDialog;
  }

  get showAlertDialog() {
    return this.#showAlertDialog;
  }

  get showConfirmDialog() {
    return this.#showConfirmDialog;
  }

  async showConfirmCloseDialog(options: ConfirmDialogOptions = {}) {
    const title = options.title || I18nClient.t('unsavedChangesPromptTitle');
    const message = options.message || I18nClient.t('unsavedChangesPrompt');
    const severity = options.severity || 'warning';
    return await this.#showConfirmDialog({
      ...options,
      title,
      message,
      severity,
    });
  }

  async showMaxPlanesDialog() {
    return await this.#showAlertDialog({
      title   : I18nClient.t('unableToOpen'),
      message : I18nClient.t('onlyNItemsCanBeOpened', { maxNumberOfItems: MAX_NUMBER_OF_PLANES }),
      severity: 'error',
    });
  }

  async showRestoreProjectDialog(options: ConfirmDialogOptions = {}) {
    return await this.#showConfirmDialog({
      title: I18nClient.t('restoreProjectTitle'),
      message:
        createElement(Stack, {
          // @ts-expect-error-next-line - Bad MtbUI Types
          gap     : 2,
          children: [
            createElement(Typography, { key: 'restoreProjectInfo', children: I18nClient.t('restoreProjectConfirmationInfo') }),
            createElement(Typography, { key: 'restoreProjectConfirmation', children: I18nClient.t('restoreProjectConfirmation') }),
          ],
        }),
      confirm : I18nClient.t('restoreProject'),
      cancel  : I18nClient.t('cancel'),
      severity: 'warning',
      ...options,
    });
  }

  initialize(handlers: InitializeDialogsOptions) {
    if (this.#initialized) {
      throw new Error('DialogsClient has already been initialized.');
    }

    this.#showOpenInDialog = handlers?.showOpenInDialog;
    this.#showStorageExplorerDialog = handlers?.showStorageExplorerDialog;
    this.#showPlaneDialog = handlers?.showPlaneDialog;
    this.#showAlertDialog = handlers?.showAlertDialog;
    this.#showConfirmDialog = handlers?.showConfirmDialog;
    this.#initialized = true;
  }
}

const dialogsClient = new DialogsClient();
export default dialogsClient;
