import type { OpenEventPayload } from './types';
import type { Plane } from '../../core/store/reducers/planes/types';
import type { ModuleConfigKey } from '../../modules/types';
import type { IntegratedPlatformModule } from '../../types';
import type PlaneChannel from '../channel/plane-channel';
import PlatformModule from '../../core/platform-module';
import LoggerClient from '../logger';
import ModuleQueue from './queue';

/**
 * ModuleEventClient manages any events configured by the remote module during the `init` function.
 * It stores the events in a map by module key for later access and integration within Platform.
 */
class ModuleEventManager {
  #logger = LoggerClient.createNamedLogger('ModuleEventManager');

  #getModuleApi(plane: Plane): IntegratedPlatformModule {
    return PlatformModule.getOrCreateIntegratedPlatformModule(plane.id);
  }

  async flushEvents(moduleKey: ModuleConfigKey): Promise<void> {
    ModuleQueue.flush(moduleKey);
  }

  pulse(plane: Plane): Promise<void> {
    return ModuleQueue.fireOrQueue<void>(plane.module, (config) => {
      this.#logger.log(`Firing ${plane.module} "onPulse" event`);
      return config?.events?.onPulse?.(this.#getModuleApi(plane));
    });
  }

  cleanup(plane: Plane): Promise<void> {
    return ModuleQueue.fireOrQueue<void>(plane.module, (config) => {
      this.#logger.log(`Firing ${plane.module} "onCleanup" event`);
      return config?.events?.onCleanup?.(this.#getModuleApi(plane));
    });
  }

  close(plane: Plane): Promise<void> {
    return ModuleQueue.fireOrQueue<void>(plane.module, (config) => {
      this.#logger.log(`Firing ${plane.module} "onClose" event`);
      return config?.events?.onClose?.(this.#getModuleApi(plane));
    });
  }

  flush(plane: Plane): Promise<void> {
    return ModuleQueue.fireOrQueue<void>(plane.module, (config) => {
      this.#logger.log(`Firing ${plane.module} "onFlush" event`);
      return config?.events?.onFlush?.(this.#getModuleApi(plane));
    });
  }

  open(plane: Plane, payload: OpenEventPayload): Promise<void> {
    return ModuleQueue.fireOrQueue<void>(plane.module, (config) => {
      this.#logger.log(`Firing ${plane.module} "onOpen" event with payload:`, payload);
      return config?.events?.onOpen?.(this.#getModuleApi(plane), payload);
    });
  }

  openChannel(plane: Plane, channel: PlaneChannel): Promise<void> {
    return ModuleQueue.fireOrQueue<void>(plane.module, async (config) => {
      this.#logger.log(`Firing ${plane.module} "onOpenChannel" event with channel:`, channel);
      return config?.events?.onOpenChannel?.(this.#getModuleApi(plane), channel);
    });
  }
}

const moduleEventManager = new ModuleEventManager();
export default moduleEventManager;
