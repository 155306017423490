import { CloudStorageApi } from '@mtb/cloud-storage';
import type { SignOutEventDetail } from '@mtb/cloud-storage/types';
import I18nClient from '../clients/i18n';
import type { InitializeI18nOptions } from '../clients/i18n/types';
import LoggerClient from '../clients/logger';
import ModuleClient from '../clients/module';
import config from '../config';
import ChannelService from './services/channel';
import DataService from './services/data';
import DataSyncService from './services/data-sync';
import DialogsService from './services/dialogs';
import HealthService from './services/health';
import ModuleService from './services/module';
import PlaneService from './services/plane';
import RoutingService from './services/routing';
import SnackbarService from './services/snackbar';
import UserService from './services/user';
import platformStore, { PlatformProvider, useSelector } from './store';
import remoteModuleUrlConfigs from './utils/remote-url-configs';

/**
 * Represents the core functionality of Platform
 */
export class PlatformCore {
  /**
   * @deprecated This will be removed in the future.
   */
  #remoteModuleUrlConfigs: typeof remoteModuleUrlConfigs = remoteModuleUrlConfigs;
  get remoteModuleUrlConfigs() {
    return this.#remoteModuleUrlConfigs;
  }

  /**
   * @deprecated This will be removed in the future. Use respective service/client methods instead.
   */
  get getState() {
    return platformStore.getState;
  }

  /**
   * @deprecated This will be removed in the future. Use respective service/client methods instead.
   *
   */
  get selectors() {
    return platformStore.selectors;
  }

  /**
   * @deprecated This will be removed in the future. Use respective service/client methods instead.
   */
  get actions() {
    return platformStore.actions;
  }

  /**
   * @deprecated This will be removed in the future. Use respective service/client selector hooks instead.
   */
  get useSelector() {
    return useSelector;
  }

  get Provider() {
    return PlatformProvider;
  }

  #dialogs = new DialogsService();
  get Dialogs() {
    return this.#dialogs;
  }

  #i18n = I18nClient;
  get I18n() {
    return this.#i18n;
  }

  #snackbar = new SnackbarService();
  get Snackbar() {
    return this.#snackbar;
  }

  #plane = new PlaneService();
  get Plane() {
    return this.#plane;
  }

  #routing = new RoutingService();
  get Routing() {
    return this.#routing;
  }

  #health = new HealthService();
  get Health() {
    return this.#health;
  }

  #events = ModuleClient.Events;
  get Events() {
    return this.#events;
  }

  #logger = LoggerClient.createNamedLogger('PlatformCore');
  get Logger() {
    return this.#logger;
  }

  #user = new UserService();
  get User() {
    return this.#user;
  }

  #modules = new ModuleService();
  get Modules() {
    return this.#modules;
  }

  #data = new DataService();
  get Data() {
    return this.#data;
  }

  #dataSync = new DataSyncService();
  get DataSync() {
    return this.#dataSync;
  }

  #channel = new ChannelService();
  get Channel() {
    return this.#channel;
  }

  constructor() {
    CloudStorageApi.Provider.onSignIn(() => this.#routing.removeAllInterceptors());
    CloudStorageApi.Provider.onSignOut((event: CustomEvent<SignOutEventDetail>) => {
      platformStore.actions.closeAllProviderPlanes(event.detail.type);
    });

    // Expose Platform to the window object in development mode.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Ignore window type error.
    if (config.dev_tools_enabled) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - Ignore window type error.
      window.Platform = this;
    }
  }

  /**
   * Initialize Platform with the given options to better
   * control aspects of client behavior when performing inner platform operations.
   * @param i18n The options to initialize the i18n client with.
   */
  initialize(i18n: InitializeI18nOptions) {
    I18nClient.initialize(i18n);
    this.#user.updateLocale(this.I18n.detectLocale());
  }
}

const platformCore = new PlatformCore();
export default platformCore;
