import type { Relay, RelayPartyInfo } from './types';
import platformStore, { useSelector } from '../../store';

class RelaySubService {
  createOrConnect(parties: { source: RelayPartyInfo; peer: RelayPartyInfo }): Relay {
    let existingRelay;
    if (parties.source && parties.peer) {
      existingRelay = platformStore.selectors.relayByPartyId(platformStore.getState(), parties.source.id);
      existingRelay ??= platformStore.selectors.relayByPartyId(platformStore.getState(), parties.peer.id);
    } else {
      const partyId = parties.peer?.id ?? parties.source?.id;
      existingRelay = platformStore.selectors.relayByPartyId(platformStore.getState(), partyId);
    }

    if (existingRelay) {
      const update: Partial<Relay> = {
        ...existingRelay,
        source: parties.source ?? existingRelay.source,
        peer  : parties.peer ?? existingRelay.peer,
      };
      platformStore.actions.updateRelay(existingRelay.id, update);
    } else {
      platformStore.actions.createRelay({ ...parties });
    }

    const relay = this.getRelayByParty(parties.peer?.id ?? parties.source?.id);
    if (!relay) {
      throw new Error('Relay should have been created but was not found');
    }

    return relay;
  }

  disconnect(partyId: string): void {
    const relay = platformStore.selectors.relayByPartyId(platformStore.getState(), partyId);
    if (!relay) {
      return;
    }
    const party = relay.source?.id === partyId ? 'source' : relay.peer?.id === partyId ? 'peer' : undefined;
    if (!party) {
      return;
    }

    let patch: Partial<Relay> = {};
    if (party === 'peer') {
      patch = { peer: undefined };
    } else if (party === 'source') {
      patch = { source: undefined };
    } else {
      return;
    }

    platformStore.actions.updateRelay(relay.id, patch);
  }

  getRelay(relayId: string): Relay | undefined {
    return platformStore.selectors.relay(platformStore.getState(), relayId);
  }

  getRelayByParty(partyId: string): Relay | undefined {
    return platformStore.selectors.relayByPartyId(platformStore.getState(), partyId);
  }

  useRelay(partyId: string): Relay | undefined {
    return useSelector(store => platformStore.selectors.relayByPartyId(store, partyId));
  }

  updateRelay(relayId: string, relayUpdates: Partial<Relay>) : void {
    const relay = platformStore.selectors.relay(platformStore.getState(), relayId);
    if (!relay) {
      return undefined;
    }

    platformStore.actions.updateRelay(relay.id, relayUpdates);
  }
}

const relayService = new RelaySubService();

export default relayService;
