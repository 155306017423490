import type { RelaysSlice } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { closeStaleRelays } from './thunk';

export const closeStaleRelaysReducer = (builder: ActionReducerMapBuilder<RelaysSlice>) => {
  return (
    builder
      .addCase(closeStaleRelays.pending, () => undefined)
      .addCase(closeStaleRelays.fulfilled, (state, action) => {
        action.payload.forEach(staleRelayId => {
          delete state[staleRelayId];
        });
      })
      .addCase(closeStaleRelays.rejected, () => undefined)
  );
};
