/* eslint-disable @typescript-eslint/no-unused-vars */
import type { ModuleConfigKey } from '../../types';
import type { DataSyncOptions, GenerateDataOptions, GenerateDataResponse, GetLatestOptions, GetLatestResponse } from './types';

/**
 * Represents the actions configuration for a platform remote module.
 */
class RemoteModuleConfigActions {
  invokeModuleMethod = (_moduleKey: ModuleConfigKey, _methodName: string, ..._args: unknown[]): Promise<unknown> =>
    Promise.resolve();

  dataSync = {
    updateFromSource: (_options: DataSyncOptions): Promise<void> => Promise.resolve(),
    getLatest       : (_options: GetLatestOptions): Promise<GetLatestResponse> => Promise.resolve(undefined),
    generateData    : (_options: GenerateDataOptions): Promise<GenerateDataResponse> => Promise.resolve(undefined),
  };
}

export default RemoteModuleConfigActions;
