import type { AddChannelOptions, Observer } from './types';
import type { PlaneId } from '../../types';
import PlaneChannel from './plane-channel';

/**
 * @class ChannelClient
 * @description
 * Provides core functionality for managing plane channels, including creating, retrieving,
 * disconnecting, and observing changes in channels.
 */
class ChannelClient {
  #channels: PlaneChannel[] = [];
  #observers: Observer[] = [];

  /**
   * Retrieves all channels currently managed by this service.
   */
  get channels(): PlaneChannel[] {
    return [...this.#channels];
  }

  /**
   * Finds and retrieves a channel by its plane ID.
   * @param {string} planeId - The plane ID to search for.
   * @returns {PlaneChannel | undefined} - The found channel, or undefined if not found.
   */
  getChannel(planeId: string): PlaneChannel | undefined {
    return this.#channels.find(channel => channel.planeId === planeId);
  }

  /**
   * Retrieves all channels associated with the specified plane ID.
   * @param planeId - The plane ID to search for.
   * @returns {PlaneChannel[]} - An array of channels associated with the plane ID.
   */
  getPeerChannels(planeId: string): PlaneChannel[] {
    return this.#channels.filter(channel => channel.peerPlaneId === planeId);
  }

  /**
   * TODO: Look into if we can use `useSyncExternalStore` to sync the channel state instead of using observers.
   * Adds an observer to be notified when channel changes occur.
   * @param {Observer} observer - The observer function to register.
   */
  addObserver(observer: Observer): void {
    if (typeof observer === 'function' && !this.#observers.includes(observer)) {
      this.#observers.push(observer);
    }
  }

  /**
   * Removes a registered observer.
   * @param {Observer} observer - The observer function to unregister.
   */
  removeObserver(observer: Observer): void {
    this.#observers = this.#observers.filter(obs => obs !== observer);
  }

  /**
   * Notifies all registered observers about changes in the channel state.
   */
  #notifyObservers(): void {
    this.#observers.forEach(observer => observer?.());
  }

  /**
   * Disconnects all channels associated with the specified plane ID, this includes all peers and self.
   * Notifies observers after the operation.
   * @param {string} planeId - The ID of the plane whose channels should be disconnected.
   */
  disconnect(planeId: string): void {
    const peerChannels = this.getPeerChannels(planeId);
    peerChannels.forEach(channel => channel.send?.('DISCONNECT', {}));
    this.#channels = this.#channels.filter(channel => channel.peerPlaneId !== planeId || channel.planeId === planeId);
    this.#notifyObservers();
  }

  /**
   *
   * @param {PlaneChannel} channel
   * @param {string} planeId
   */
  add(channel: PlaneChannel, planeId: PlaneId, options: AddChannelOptions): PlaneChannel {
    if (!this.#channels.some(c => c.planeId === channel.planeId)) {
      this.#channels.push(channel);
    }
    const peerChannel = new PlaneChannel(planeId, channel.planeId);
    peerChannel.setActions(options?.actions || {});
    peerChannel.setMessageHandler(options?.messageHandler);
    peerChannel.connect?.(channel);
    this.#notifyObservers();

    return peerChannel;
  }

  /**
   * Removes a channel by its plane ID.
   * Notifies observers after the operation.
   * @param {string} planeId - The plane ID of the channel to remove.
   */
  remove(planeId: string): void {
    this.#channels = this.#channels.filter(channel => channel.planeId !== planeId);
    this.#notifyObservers();
  }
}

const channelClient = new ChannelClient();
export default channelClient;
