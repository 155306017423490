import type { StorageProviderEmailUser } from '../../../../types';
import type { SelectedEmail } from '../types';
import type { ChipProps, ListItemProps, TextFieldProps } from '@mtb/ui/types/components';
import type { PaletteColor } from '@mtb/ui/types/core/theme/createTheme/createPalette';
import type { SXProp } from '@mtb/ui/types/types';
import React, { useCallback, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Chip,
  ErrorIcon,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Tooltip,
} from '@mtb/ui';
import { useQuery } from '@tanstack/react-query';
import ProviderClient from '../../../clients/provider';
import { useDebounce } from '../../../hooks';
import { useTranslation } from '../../../services/i18n';
import { validateEmail } from '../../../utils';
import { useShareFileDialog } from '../context';

function getRandomIndexByString(str: string, arrayLength: number) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash; // Convert to 32bit integer
  }
  const pseudoRandomNumber = Math.abs(hash);
  const index = pseudoRandomNumber % arrayLength;
  return index;
}

function getAvatarBackgroundColor(str: string, colorCycle: PaletteColor[]) {
  const rand = getRandomIndexByString(str, colorCycle.length);
  return colorCycle[rand];
}

const EmailChipErrorIcon = ({ ...other }) => {
  const [t] = useTranslation();
  return (
    // @ts-expect-error-next-line: Bad MtbUI Types
    <Tooltip
      placement="bottom"
      title={t('connection.invalidEmail')}>
      {/* @ts-expect-error-next-line: Bad MtbUI Types */}
      <ErrorIcon
        {...other}
        color="error" />
    </Tooltip>
  );
};

const EmailAvatar = ({ email, ...other }: { email: string, sx?: SXProp }) => (
  <Avatar
    {...other}
    sx={{
      ...(other as { sx?: SXProp }).sx,
      // @ts-expect-error-next-line: Bad MtbUI Types
      color          : t => t.palette.default.light,
      // @ts-expect-error-next-line: Bad MtbUI Types
      backgroundColor: t => getAvatarBackgroundColor(email ?? '', t.palette.colorCycle).main,
    }}>
    {email?.[0]?.toUpperCase()}
  </Avatar>
);

export const AutocompletePeople = () => {
  const [t] = useTranslation();
  const {
    selectedEmails,
    setSelectedEmails,
    allEmailsValid,
    connection,
    emailInputRef,
  } = useShareFileDialog();

  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue);

  const { data: possibleEmails } = useQuery({
    queryKey: ['people', connection.type, debouncedInputValue],
    queryFn : ({ signal }) => {
      return ProviderClient.searchPeople(connection.type, debouncedInputValue, signal);
    },
    placeholderData: [],
    enabled        : debouncedInputValue.length > 0,
  });

  const handleOnChange = useCallback(
    async (_e: unknown, selectedEmails: (SelectedEmail | StorageProviderEmailUser | string)[]) => {
      setSelectedEmails(selectedEmails.map((e): SelectedEmail => {
        if (typeof (e as SelectedEmail).isValid === 'boolean') {
          return e as SelectedEmail;
        }
        if (typeof e === 'string') {
          return {
            email  : e,
            isValid: validateEmail(e),
          };
        }
        return {
          email  : (e as StorageProviderEmailUser).email,
          isValid: validateEmail((e as StorageProviderEmailUser).email),
        };
      }));
    },
    [setSelectedEmails],
  );

  const handleOnInputChange = useCallback(
    async (_e: unknown, value: string) => {
      setInputValue(value);
    },
    [],
  );

  const handleRenderTag = useCallback(
    (tags: SelectedEmail[], getTagProps: ({ index }: { index: number }) => Partial<ChipProps> & { key: React.Key }) =>
      tags.map((option, index: number) => {
        const { key, ...tagProps } = getTagProps({ index });
        return (
          // @ts-expect-error-next-line: Bad MtbUI Types
          <Chip
            key={key}
            label={option.email}
            variant="outlined"
            {...tagProps}
            {...(option.isValid ? {
              avatar: <EmailAvatar email={option.email} />,
              color : 'default',
            } : {
              icon : <EmailChipErrorIcon />,
              color: 'error',
            })} />
        );
      }),
    [],
  );

  const handleRenderOption = useCallback(
    (props: Partial<ListItemProps> & { key: React.Key }, option: StorageProviderEmailUser) => {
      const { key, ...listItemProps } = props;
      return (
        // @ts-expect-error-next-line: Bad MtbUI Types
        <ListItemButton
          key={key}
          as={ListItem}
          {...listItemProps}>
          {/* @ts-expect-error-next-line: Bad MtbUI Types */}
          <ListItemAvatar sx={{ width: 30, height: 30, minWidth: 30, mr: 1.5 }}>
            <EmailAvatar
              email={option.email}
              sx={{ width: 30, height: 30 }} />
          </ListItemAvatar>
          {/* @ts-expect-error-next-line: Bad MtbUI Types */}
          <ListItemText
            primary={option.name}
            secondary={option.email}
            sx={{ margin: 0 }} />
        </ListItemButton>
      );
    },
    [],
  );

  const handleRenderInput = useCallback(
    (params: TextFieldProps) => (
      // @ts-expect-error-next-line: Bad MtbUI Types
      <TextField
        {...params}
        error={!allEmailsValid}
        inputRef={emailInputRef} />
    ),
    [allEmailsValid, emailInputRef],
  );

  return (
    <Autocomplete
      autoSelect
      componentsProps={{
        // @ts-expect-error-next-line: Bad MtbUI Types
        Input: {
          type: 'email',
          sx  : { maxHeight: 120 /* ~3 inputs tall */, overflowY: 'auto' },
        },
      }}
      filterOptions={o => o}
      // @ts-expect-error-next-line: Bad MtbUI Types
      freeSolo
      // @ts-expect-error-next-line: Bad MtbUI Types
      getOptionLabel={o => o.email}
      inputValue={inputValue}
      label={t('connection.addPeople')}
      // @ts-expect-error-next-line: Bad MtbUI Types
      multiple
      options={possibleEmails}
      // @ts-expect-error-next-line: Bad MtbUI Types
      placeholder={t('connection.addEmail')}
      renderInput={handleRenderInput}
      // @ts-expect-error-next-line: Bad MtbUI Types
      renderOption={handleRenderOption}
      renderTag={handleRenderTag}
      value={selectedEmails}
      // @ts-expect-error-next-line: Bad MtbUI Types
      onChange={handleOnChange}
      onInputChange={handleOnInputChange} />
  );
};

