import { useCallback, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Spacer,
  Stack,
  Typography,
  useEventCallback,
} from '@mtb/ui';
import { createSchema } from '@mtb/validation';
import { INVALID_NAME_CHARS_PATTERN } from '../../../constants';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';
import { StorageProjectMenuFileDownload } from './StorageProjectMenuFileDownload';

const validationSchema = createSchema().addStringField('value', {
  isRequired: true,
  pattern   : INVALID_NAME_CHARS_PATTERN,
});

export function StorageProjectMenuFileName({ onBeforeDownload }) {
  const [t] = useTranslation();
  const { project } = useStorageProjectMenu();
  const [localName, setLocalName] = useState(null);

  const handleOnChange = useEventCallback(({ target: { value } }) =>
    setLocalName(value),
  );

  const handleOnFocus = useEventCallback(({ target }) => {
    target.select();
    setLocalName(target.value);
  });

  const handleOnBlur = useCallback(async () => {
    const { valid } = validationSchema.validate({ value: localName });
    if (valid) {
      await project.rename(`${localName}.${project.extension}`);
    }
    setLocalName(null);
  }, [localName, project]);

  return (
    <FormControl
      fullWidth
      id="mtb-cloud-storage-project-menu-name">
      <Stack
        alignItems="center"
        direction="row">
        <FormLabel>{t('connection.fileName')}</FormLabel>
        <Spacer />
        <StorageProjectMenuFileDownload onBeforeDownload={onBeforeDownload} />
      </Stack>
      <Input
        disabled={!project.canRenameProject}
        endAdornment={
          <Typography data-testid="storage-project-menu-name-extension">{`.${project.extension}`}</Typography>
        }
        inputProps={{ 'data-testid': 'storage-project-menu-name-input' }}
        value={localName ?? project.displayName}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus} />
    </FormControl>
  );
}
