import type { DataSyncRelay, DataSyncRelayPeer, DataSyncRelaySource } from './types';
import type { ModuleConfigKey } from '../../../types';
import type { GetLatestResponse } from '../../remote-module-config/types';
import type { Relay, RelayParty } from '../../sub-services/relay/types';
import DialogsClient from '../../../clients/dialogs';
import ModuleClient from '../../../clients/module';
import platformStore from '../../store';
import RelaySubService from '../../sub-services/relay';

type DataSyncRelayPartyState = undefined | {
  version?: string;
};

async function verifyPlaneHealth(planeId: string): Promise<boolean> {
  const plane = platformStore.selectors.plane(platformStore.getState(), planeId);
  await platformStore.actions.checkPlaneHealth(plane.id);
  const planeHealth = platformStore.selectors.planeHealth(platformStore.getState(), plane.id);

  if (planeHealth.isHealthy) {
    return true;
  }
  // If the plane is not healthy, prompt the user to restore it. If the plane is dead,
  // the restore operation will no-op and display a dead plane message.
  const shouldRestore = await DialogsClient.showRestoreProjectDialog();
  if (shouldRestore) {
    platformStore.actions.setCurrentLayout({
      currentModuleKey: plane.module,
      currentPlaneId  : plane.id,
    });
  }

  return false;
}

export function getDataSyncRelay(relay: Relay): DataSyncRelay {
  let peer: DataSyncRelayPeer | undefined;
  if (relay.peer) {
    const peerState = relay.peer.state as DataSyncRelayPartyState;
    peer = {
      ...relay.peer,
      version         : peerState?.version ?? '',
      setVersion      : (version: string) => setVersion(relay.id, version, 'peer'),
      getLatest       : () => getLatest(relay.id, false, 'peer'),
      updateFromSource: () => updateFromSource(relay.id),
    };
  }

  let source: DataSyncRelaySource | undefined;
  if (relay.source) {
    const sourceState = relay.source.state as DataSyncRelayPartyState;
    source = {
      ...relay.source,
      version   : sourceState?.version ?? '',
      setVersion: (version: string) => setVersion(relay.id, version, 'source'),
      getLatest : opts => getLatest(relay.id, opts?.includeData ?? false, 'source'),
    };
  }

  const isConnected = Boolean(relay.source?.planeId && relay.peer?.planeId);
  const isSynced = Boolean(isConnected && source?.version && peer?.version && source.version === peer.version);

  return {
    isConnected,
    isSynced,
    peer,
    source,
  };
}

async function updateFromSource(relayId: string): Promise<void> {
  const relay = getRelay(relayId);
  if (!relay) {
    return Promise.resolve();
  }

  const module = getPartyModule(relay, 'peer');
  if (!module) {
    return Promise.resolve();
  }

  if (!relay.peer?.planeId) {
    return Promise.resolve();
  }

  const isHealthy = await verifyPlaneHealth(relay.peer.planeId);
  if (!isHealthy) {
    return Promise.resolve();
  }

  return ModuleClient.Actions.DataSync.updateFromSource(module, relay);
}

function setVersion(relayId: string, activeVersion: string, party: RelayParty): void {
  const relay = getRelay(relayId);
  if (!relay) {
    return undefined;
  }

  RelaySubService.updateRelay(relay.id, {
    [party]: {
      ...relay[party],
      state: {
        version: activeVersion,
      },
    },
  });
}

async function getLatest(relayId: string, includeData: boolean, party: RelayParty): Promise<GetLatestResponse> {
  const relay = getRelay(relayId);
  if (!relay) {
    return undefined;
  }

  const module = getPartyModule(relay, party);
  if (!module) {
    return undefined;
  }

  // Limit the check to the "peer" party, which for now is just only happening for MSSO.
  // When we want this to go both ways just remove the if statement.
  if (party === 'peer') {
    const planeId = party === 'peer' ? relay.peer?.planeId : relay.source?.planeId;
    if (!planeId) {
      return undefined;
    }

    const isHealthy = await verifyPlaneHealth(planeId);
    if (!isHealthy) {
      return undefined;
    }
  }

  return ModuleClient.Actions.DataSync.getLatest(module, relay, includeData);
}

function getRelay(relayId: string): Relay | undefined {
  return RelaySubService.getRelay(relayId);
}

function getPartyModule(relay: Relay, party: RelayParty): ModuleConfigKey | undefined {
  const planeId = party === 'peer' ? relay.peer?.planeId : relay.source?.planeId;
  if (!planeId) {
    return;
  }

  const plane = platformStore.selectors.plane(platformStore.getState(), planeId);
  if (!plane) {
    return;
  }

  return plane.module;
}
