import type { StorageProviderKey } from '@';
import {
  Collapse,
  FolderOpenedOutlinedIcon,
  FolderOutlinedIcon,
  FolderSharedOutlinedIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SettingsIcon,
  Stack,
  Typography,
} from '@mtb/ui';
import { CLOUD_STORAGE_CATEGORIES } from '../../../../constants';
import { useProvider } from '../../../../hooks';
import { useTranslation } from '../../../../services/i18n';
import { ProviderCategoryButton } from '../ProviderCategoryButton';

type ProviderListProps = {
  provider: StorageProviderKey;
  providerName: string;
  providerIcon: React.ReactNode;
};
export const ProviderList = ({ provider: providerProp, providerName, providerIcon }: ProviderListProps) => {
  const [t] = useTranslation();
  const { isSignedIn } = useProvider(providerProp);

  return (
    // @ts-expect-error - Bad MtbUI Types
    <List>
      {/* @ts-expect-error - Bad MtbUI Types */}
      <ListItem>
        {/* @ts-expect-error - Bad MtbUI Types */}
        <ListItemIcon>{providerIcon}</ListItemIcon>
        {/* @ts-expect-error - Bad MtbUI Types */}
        <ListItemText>
          <Typography
            noWrap
            variant="title-sm"
            weight="regular">
            {providerName}
          </Typography>
        </ListItemText>
      </ListItem>
      <Stack
        pl={2.5}
        pt={1}>
        {/* @ts-expect-error - Bad MtbUI Types */}
        <Collapse
          data-testid="provider-list-collapse"
          in={isSignedIn}>
          <ProviderCategoryButton
            category={CLOUD_STORAGE_CATEGORIES.ALL}
            provider={providerProp}
            title={t('connection.all')}>
            {/* @ts-expect-error - Bad MtbUI Types */}
            <FolderOutlinedIcon />
          </ProviderCategoryButton>
          <ProviderCategoryButton
            category={CLOUD_STORAGE_CATEGORIES.RECENT}
            provider={providerProp}
            title={t('connection.recent')}>
            {/* @ts-expect-error - Bad MtbUI Types */}
            <FolderOpenedOutlinedIcon />
          </ProviderCategoryButton>
          <ProviderCategoryButton
            category={CLOUD_STORAGE_CATEGORIES.SHARED}
            provider={providerProp}
            title={t('connection.shared')}>
            {/* @ts-expect-error - Bad MtbUI Types */}
            <FolderSharedOutlinedIcon />
          </ProviderCategoryButton>
        </Collapse>
        <ProviderCategoryButton
          category={CLOUD_STORAGE_CATEGORIES.NONE}
          provider={providerProp}
          title={t('connection.settings')}>
          {/* @ts-expect-error - Bad MtbUI Types */}
          <SettingsIcon />
        </ProviderCategoryButton>
      </Stack>
    </List>
  );
};
