import type { UseCloudExplorerItemReturnProps } from '../../hooks';
import type { StorageExplorerItem, StorageProviderItem } from '@';
import { Stack, Typography } from '@mtb/ui';
import { isGoogleSheet } from '../../../../utils';
import { CloudExplorerTableRowActions } from './CloudExplorerTableBody/CloudExplorerTableRow/CloudExplorerTableRowActions';

const createRandomString = (minLength: number, maxLength: number) => {
  const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  return Array.from({ length }, () =>
    String.fromCharCode(Math.floor(Math.random() * 26) + 97),
  ).join('');
};

/**
 * The Skeleton items to display when loading, we generate random strings for values such as the name, owner, and lastModifiedElapsedTime
 * so that the skeletons will take up the dimensions of the content. This helps create skeletons that are more visually
 * consistent with the actual content and it's variations in dimensions.
 */
export const SKELETON_ITEMS = Array.from({ length: 25 }, (_, i) => ({
  id                     : `skeleton-${i}`,
  name                   : createRandomString(13, 33),
  lastModifiedElapsedTime: createRandomString(8, 13),
  lastAccessedElapsedTime: createRandomString(8, 13),
  createdBy              : {
    name: createRandomString(11, 21),
  },
  folder: false,
})) as StorageExplorerItem[];

export type StorageExplorerColumn = {
  id : string;
  label : string;
  render : ((row: StorageProviderItem & UseCloudExplorerItemReturnProps) => JSX.Element) | (() => JSX.Element | null);
  width : number;
  paddingY? : number;
  align : 'left' | 'center' | 'right';
  disableSkeleton?: boolean;
};
export type StorageExplorerColumns = {
  [columnKey: string]: StorageExplorerColumn;
}

export const CLOUD_EXPLORER_COLUMNS: StorageExplorerColumns = {
  NAME: {
    id    : 'name',
    label : 'connection.name',
    render: (row) => {
      const name = isGoogleSheet(row) ? row?.displayName : row?.name;
      return (
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          title={name}>
          {row?.icon}
          <Typography noWrap>{name}</Typography>
        </Stack>
      );
    },
    width: 300,
    align: 'left',
  },
  LAST_ACCESSED: {
    id    : 'lastAccessed',
    label : 'connection.opened',
    render: (row) => <Typography noWrap>{row?.lastAccessedElapsedTime}</Typography>,
    width : 150,
    align : 'left',
  },
  LAST_MODIFIED: {
    id    : 'lastModified',
    label : 'connection.lastModified',
    render: (row) => <Typography noWrap>{row?.lastModifiedElapsedTime}</Typography>,
    width : 150,
    align : 'left',
  },
  OWNER: {
    id    : 'owner',
    label : 'connection.owner',
    render: (row) => <Typography noWrap>{row?.createdBy?.name}</Typography>,
    width : 175,
    align : 'left',
  },
  ACTIONS: {
    id             : 'actions',
    label          : '',
    render         : CloudExplorerTableRowActions,
    width          : 150,
    paddingY       : 0.5,
    align          : 'right',
    disableSkeleton: true,
  },
};
