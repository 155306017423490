import type { DataItem } from './types';
import type { CloudStoragePassthroughItem, StorageProviderItem } from '@mtb/cloud-storage/types';
import CloudStorageClient from '../cloud-storage';
import LoggerClient from '../logger';
import CloudStorageProject from './cloud-storage-project';
import { createDataRef } from './utils';

class CloudItem implements DataItem {
  #item: StorageProviderItem;

  #logger = LoggerClient.createNamedLogger(`CloudStorageItem:${this.name}`);

  get id() {
    return createDataRef({
      name      : `${this.name}.${this.extension}`,
      connection: this.connection,
    });
  }

  get name() {
    return CloudStorageClient.getNameParts(this.#item?.name).displayName;
  }

  get displayName() {
    return CloudStorageClient.getNameParts(this.#item?.name).displayName;
  }

  // some workflows don't have the extension in the item's name
  // TODO: solidify the usage of `name` and `displayName` from cloud storage
  get extension() {
    const { extension } = CloudStorageClient.getNameParts(this.#item.name);
    return extension || this.#item.extension;
  }

  get size() {
    return this.#item.size;
  }

  get connection() {
    return {
      id     : this.#item.id,
      driveId: this.#item.driveId,
      type   : this.#item.type,
    };
  }

  constructor(item: StorageProviderItem) {
    try {
      if (!item) {
        throw new Error('Invalid item.');
      }
      this.#item = { ...item };
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Check if the name of the item matches the given name.
   * @param name - The name to check against.
   * @returns - Whether the name matches.
   */
  doesNameMatch(name: string) {
    return `${this.name}.${this.extension}` === name;
  }

  /**
   * Verify the item before opening it.
   * @returns - Whether the item is able to be opened.
   */
  verifyBeforeOpen() {
    const canOpen = CloudStorageClient.verifyBeforeOpen(this.connection);
    return canOpen;
  }

  /**
   * Duplicate the item.
   * @returns - A promise that resolves when the item has been duplicated.
   */
  async duplicate() {
    try {
      const duplicatedItem = await CloudStorageClient.duplicateItem(this.#item);
      if (!duplicatedItem) {
        throw new Error('Failed to duplicate item.');
      }
      this.#item = { ...duplicatedItem };
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Rename the item.
   * @param name - The new name of the item.
   * @returns - A promise that resolves when the item has been renamed.
   */
  async rename(name: string) {
    try {
      const renamedItem = await CloudStorageClient.renameItem(this.#item, name);
      if (!renamedItem) {
        throw new Error('Failed to rename item.');
      }
      this.#item = {
        ...this.#item,
        name: renamedItem,
      };
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Create a passthrough item from the item.
   * @returns - A promise that resolves with the passthrough item.
   */
  async createPassthrough(): Promise<CloudStoragePassthroughItem> {
    try {
      const passthroughItem = await CloudStorageClient.openPassthroughItem(this.#item);
      if (!passthroughItem || typeof passthroughItem === 'boolean') {
        throw new Error('Failed to create passthrough item.');
      }
      return passthroughItem;
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Create a project from the item.
   * @param name - The name of the project.
   * @returns - A promise that resolves with the project.
   */
  async createProject(name: string): Promise<CloudStorageProject> {
    try {
      const projectItem = { ...this.#item, ...(name && { name }) };
      const project = await CloudStorageClient.openProject(projectItem);
      if (!project || typeof project === 'boolean') {
        throw new Error('Failed to create project.');
      }
      return new CloudStorageProject(project.projectId);
    } catch (error) {
      this.#logger.error(error);
      throw error;
    }
  }

  /**
   * Download the content of the item as a blob.
   * @returns - A promise that resolves with the downloaded item.
   */
  async download(): Promise<Blob> {
    return await CloudStorageClient.downloadItem(this.connection);
  }
}

export default CloudItem;
