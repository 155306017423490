import type { GenerateDataResponse, GetLatestResponse } from '../../core/remote-module-config/types';
import type { Relay } from '../../core/sub-services/relay/types';
import type { ModuleConfigKey } from '../../modules/types';
import type { DataItem } from '../data/types';
import PlatformModule from '../../core/platform-module';
import MODULES from '../../modules';
import LoggerClient from '../logger';
import ModuleQueue from './queue';

class DataSyncActionHandler {
  #logger = LoggerClient.createNamedLogger('DataSyncActionHandler');
  #staticApi = PlatformModule.getStaticPlatformModule();

  updateFromSource(moduleKey: ModuleConfigKey, relay: Relay): Promise<void> {
    return ModuleQueue.fireOrQueue<void>(moduleKey, async config => {
      this.#logger.log('Firing getDataFromSource action with relay:', relay);

      return config.actions?.dataSync?.updateFromSource({
        module: this.#staticApi,
        relay,
      });
    });
  }

  async getLatest(moduleKey: ModuleConfigKey, relay: Relay, includeData: boolean): Promise<GetLatestResponse> {
    return ModuleQueue.fireOrQueue<GetLatestResponse>(moduleKey, async config => {
      this.#logger.log('Firing getLatest action');

      return config.actions.dataSync.getLatest({
        module: this.#staticApi,
        relay,
        includeData,
      });
    });
  }

  async generateData(data: DataItem, steps: unknown): Promise<GenerateDataResponse> {
    const dataCenter = MODULES.DATACENTER;
    dataCenter.preloadRemoteModule?.();
    return ModuleQueue.fireOrQueue<GenerateDataResponse>(dataCenter.key, async config => {
      this.#logger.log('Firing generateData action');

      return config.actions.dataSync.generateData({
        data,
        steps,
      });
    });
  }
}

const dataSyncActionHandler = new DataSyncActionHandler();

export default dataSyncActionHandler;
