import type PlaneChannel from '../../../clients/channel/plane-channel';
import type { AddChannelOptions } from '../../../clients/channel/types';
import type { PlaneId } from '../../types';
import { useEffect, useState } from 'react';
import ChannelClient from '../../../clients/channel';
import ModuleClient from '../../../clients/module';
import platformStore from '../../store';

class ChannelService {
  /**
   * Gets all channels currently managed by the ChannelClient.
   */
  get channels() {
    return ChannelClient.channels;
  }

  /**
   * Create's a new channel instance and adds it to the ChannelClient.
   * @param {PlaneChannel} channel
   * @param {string} planeId
   */
  add(channel: PlaneChannel, planeId: PlaneId, options: AddChannelOptions): PlaneChannel {
    const peerChannel = ChannelClient.add(channel, planeId, options);
    return peerChannel;
  }

  /**
   * Broadcasts an `onOpenChannel` even that to the given plane to allow the plane to create
   * and connect it's own channel instance to the given channel.
   * @param planeId
   * @param channel
   * @returns
   */
  open(planeId: string, channel: PlaneChannel): boolean {
    const plane = platformStore.selectors.plane(platformStore.getState(), planeId);
    if (plane) {
      ModuleClient.Events.openChannel(plane, channel);
      return true;
    }
    return false;
  }

  /**
   * React hook to get and observe changes to a specific channel by ID.
   * @param {string} id - The ID of the channel to observe.
   * @returns {PlaneChannel | undefined} - The channel instance, or undefined if not found.
   */
  useChannel(id: string): PlaneChannel | undefined {
    const [channel, setChannel] = useState<PlaneChannel | undefined>(() => ChannelClient.getChannel(id));

    useEffect(() => {
      const observer = () => {
        setChannel(ChannelClient.getChannel(id));
      };

      ChannelClient.addObserver(observer);
      // Initialize state with the current channel.
      observer();

      return () => {
        ChannelClient.removeObserver(observer);
      };
    }, [id]);

    return channel;
  }
}

export default ChannelService;
