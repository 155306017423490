import type { RelaysSlice } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';

export const removeRelay = {
  reducer: (state: RelaysSlice, action: PayloadAction<{ id: string; }>) => {
    if (!state[action.payload.id]) {
      return;
    }

    delete state[action.payload.id];
  },
  prepare: (id: string) => {
    return { payload: { id } };
  },
};
