import { useCallback } from 'react';
import { Button, LinkIcon } from '@mtb/ui';
import { useQueryClient } from '@tanstack/react-query';
import ProviderClient from '../../../clients/provider';
import { useTranslation } from '../../../services/i18n';
import { useShareFileDialog } from '../context';
import { useCopiedAlertTimeout } from './useCopiedAlertTimeout';

export const CopyLinkButton = () => {
  const [t] = useTranslation();
  const queryClient = useQueryClient();
  const { permission, connection, setIsCopied } = useShareFileDialog();

  useCopiedAlertTimeout();

  const handleCopyLink = useCallback(
    async () => {
      const sharingLink = await queryClient.fetchQuery({
        queryKey: ['sharing-link', connection.type, connection.itemId, permission],
        queryFn : () => {
          return ProviderClient.getSharingLink(
            connection.type,
            connection.itemId as string,
            permission,
          );
        },
        staleTime: Infinity,
      });

      if (sharingLink) {
        navigator.clipboard.writeText(sharingLink);
        setIsCopied(true);
      }
    },
    [connection, permission, queryClient, setIsCopied],
  );

  return (
    // @ts-expect-error-next-line: Bad MtbUI Types */}
    <Button
      color="primary"
      variant="highlight"
      onClick={handleCopyLink}>
      {/* @ts-expect-error-next-line: Bad MtbUI Types */}
      <LinkIcon />
      {t('connection.copyLink')}
    </Button>
  );
};
