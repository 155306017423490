import type { DataRef } from './types';

const dataRefVersion = '1';

// Singleton instances of TextEncoder and TextDecoder
const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();

/**
 * Encodes a DataRef object into a base64 string, ensuring proper handling of non-ASCII characters.
 * @param dataRef - The DataRef object to encode.
 * @returns The encoded DataRef as a base64 string.
 * @throws Will throw an error if encoding fails.
 */
export function encodeDataRef(dataRef: Partial<DataRef>): string {
  try {
    const jsonString = JSON.stringify({
      ...dataRef,
      version: dataRefVersion,
    });

    const uint8Array = textEncoder.encode(jsonString);
    const binaryString = String.fromCharCode(...uint8Array);
    return btoa(binaryString);
  } catch (error) {
    throw Error(`Failed to encode dataRef: ${error}`);
  }
}

/**
 * Decodes a base64 string into a DataRef object, supporting non-ASCII characters.
 * @param dataRefId - The base64 encoded DataRef string.
 * @returns The decoded DataRef object.
 * @throws Will throw an error if decoding fails.
 */
export function decodeDataRef(dataRefId: string): DataRef {
  try {
    const binaryString = atob(dataRefId);
    const uint8Array = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    const jsonString = textDecoder.decode(uint8Array);
    return JSON.parse(jsonString);
  } catch (error) {
    throw Error(`Failed to decode dataRef: ${error}`);
  }
}

/**
 * Decodes the DataRef object, updates it, and then encodes it back into a base64 string.
 * @param dataRefId - The base64 encoded DataRef string.
 * @param update - The partial DataRef object to update the DataRef with.
 * @returns The updated DataRef as a base64 string.
 */
export function updateDataRef(dataRefId: string, update: Partial<DataRef> = {}): string {
  const dataRef = decodeDataRef(dataRefId);
  const updatedDataRef = {
    ...dataRef,
    ...update,
    version: dataRefVersion,
  };
  return encodeDataRef(updatedDataRef);
}

/**
 * Creates a new DataRef using the provided connection.
 * @param options - The options to create the DataRef.
 * @param options.name - The name of the DataRef.
 * @param options.connection - The connection details.
 * @returns The encoded DataRef as a base64 string.
 * @throws Will throw an error if the DataRef creation fails.
 */
export function createDataRef({
  name,
  connection,
}: {
  name: string;
  connection: Partial<DataRef['connection']>;
}): string {
  return encodeDataRef({
    name,
    connection: connection as DataRef['connection'],
  });
}
