import type { PlaneId } from '../../reducers/planes/types';
import { PLANE_HEALTH_TYPES, PLANE_STATUS_TYPES, PLANE_VARIANTS } from '../../reducers/planes/constants';
import { createSelector } from '../../redux-helpers/create-selector';

const selectors = {
  plane: createSelector(
    [state => state.planes, (state, planeId: PlaneId) => planeId],
    (planes, planeId) => planes[planeId],
  ),
  planeError: createSelector(
    [state => state.planes, (state, planeId: PlaneId) => planeId],
    (planes, planeId) => planes[planeId]?.error,
  ),
  planeHealth: createSelector([state => state.planes, (state, planeId: PlaneId) => planeId], (planes, planeId) => {
    const health = planes[planeId]?.health;
    return {
      isUnknown     : health === PLANE_HEALTH_TYPES.UNKNOWN,
      isHealthy     : health === PLANE_HEALTH_TYPES.HEALTHY,
      isUnhealthy   : health === PLANE_HEALTH_TYPES.UNHEALTHY,
      isDisconnected: health === PLANE_HEALTH_TYPES.DISCONNECTED,
      isRecoverable : health === PLANE_HEALTH_TYPES.RECOVERABLE,
    };
  }),
  planeStatus: createSelector([state => state.planes, (state, planeId: PlaneId) => planeId], (planes, planeId) => {
    const plane = planes[planeId];
    const status = plane?.status;
    const hasError = status === PLANE_STATUS_TYPES.ERROR || plane?.error !== undefined;
    return {
      isLoading: status === PLANE_STATUS_TYPES.LOADING,
      isIdle   : status === PLANE_STATUS_TYPES.IDLE,
      isPending: status === PLANE_STATUS_TYPES.PENDING,
      hasError,
    };
  }),
  planes: createSelector(
    [state => state.planes],
    // Returning the input verbatim won't memoize correctly, see: https://reselect.js.org/usage/common-mistakes
    planes => ({ ...planes }),
  ),
  persistentPlanes: createSelector([state => state.planes], planes =>
    Object.values(planes).filter(plane => plane.variant === PLANE_VARIANTS.PERSISTENT),
  ),
  planesArray        : createSelector([state => state.planes], planes => Object.values(planes)),
  planesByCreatedDate: createSelector(
    [state => Object.values(state.planes ?? {}), (state, direction) => direction],
    (planesArray, direction) =>
      planesArray.filter(p => p.variant === PLANE_VARIANTS.PERSISTENT).sort(
        (
          { createdDate: a }: { createdDate: number | string },
          { createdDate: b }: { createdDate: number | string },
        ) => {
          if (a === b || ((a === undefined || a === null) && (b === undefined || b === null))) {
            return 0;
          }
          // Swap A and B if we are sorting desc
          [a, b] = direction === 'desc' ? [b, a] : [a, b];

          // Null/undefined before empty string
          if (a === undefined || a === null) {
            return -1;
          }
          if (b === undefined || b === null) {
            return 1;
          }
          if (a === '') {
            return -1;
          }
          if (b === '') {
            return 1;
          }

          // Parse A and B to dates then get numeric value
          [a, b] = [new Date(a).valueOf(), new Date(b).valueOf()];

          // NaNs are treated like empty string - sorted first
          if (Number.isNaN(a) && Number.isNaN(b)) {
            return 0;
          }
          if (Number.isNaN(a)) {
            return -1;
          }
          if (Number.isNaN(b)) {
            return 1;
          }
          return a - b;
        },
      ),
  ),
  isPlanePending: createSelector(
    [state => state.planes, (state, planeId: PlaneId) => planeId],
    (planes, planeId) => planes[planeId]?.status === PLANE_STATUS_TYPES.PENDING,
  ),
  isPlaneIdle: createSelector(
    [state => state.planes, (state, planeId: PlaneId) => planeId],
    (planes, planeId) => planes[planeId]?.status === PLANE_STATUS_TYPES.IDLE,
  ),
  isPlaneLoading: createSelector(
    [state => state.planes, (state, planeId: PlaneId) => planeId],
    (planes, planeId) => planes[planeId]?.status === PLANE_STATUS_TYPES.LOADING,
  ),
};

export default selectors;
