export const DEFAULT_SNACKBAR_ICONS = {
  success: 'SuccessIcon',
  warning: 'WarningIcon',
  error  : 'ErrorIcon',
  info   : 'InfoIcon',
}as const;

export const SNACKBAR_SEVERITIES = ['success', 'warning', 'error', 'info'] as const;

export const DEFAULT_SNACKBAR_PROPS = {
  severity        : 'info',
  icon            : DEFAULT_SNACKBAR_ICONS.info,
  autoHideDuration: 5000,
  anchorOrigin    : {
    horizontal: 'right',
    vertical  : 'top',
  },
} as const;
