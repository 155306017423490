import type { SelectedEmail } from './types';
import type { StorageProviderSharingPermissions } from '../../../types';
import type { StoreProjectConnectionInfo } from '../../store/projects/types';
import type { UseMutationResult } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import DialogClient from '../../clients/dialog';
import ProviderClient from '../../clients/provider';

type ShareDialogProviderProps = {
  fileName: string;
  connection: StoreProjectConnectionInfo;
  onClose: (confirmed: boolean) => void;
};

type ShareDialogContextValue = ShareDialogProviderProps & {
  selectedEmails: SelectedEmail[];
  setSelectedEmails: (emails: SelectedEmail[]) => void;
  permission: StorageProviderSharingPermissions;
  setPermission: (permission: StorageProviderSharingPermissions) => void;
  isCopied: boolean;
  setIsCopied: (isCopied: boolean) => void;
  isSharing: boolean;
  shareMutation: UseMutationResult<void, Error, void, unknown>;
  allEmailsValid: boolean;
  emailInputRef: React.RefObject<HTMLInputElement>;
}

export const ShareDialogContext = createContext<ShareDialogContextValue | undefined>(undefined);

export const ShareDialogProvider = ({
  children,
  fileName,
  connection,
  onClose,
}: PropsWithChildren<ShareDialogProviderProps>) => {
  const emailInputRef = useRef(null);

  const [isCopied, setIsCopied] = useState(false);

  const [isSharing, setIsSharing] = useState(false);

  const [selectedEmails, setSelectedEmails] = useState<SelectedEmail[]>([]);

  const [permission, setPermission] = useState<StorageProviderSharingPermissions>('read');

  const allEmailsValid = useMemo(
    () => selectedEmails.every(v => v.isValid),
    [selectedEmails],
  );

  const shareMutation = useMutation({
    mutationFn: () => {
      setIsSharing(true);
      return ProviderClient.addSharingPermissions(
        connection.type,
          connection.itemId as string,
          permission,
          selectedEmails.map(e => e.email),
      );
    },
    onSuccess: () => {
      onClose(true);
    },
    onError: () => {
      DialogClient.alertDialog({
        title   : 'connection.failedToShare',
        message : 'connection.someEmailsInvalid',
        severity: 'error',
      });
    },
    onSettled: () => {
      setIsSharing(false);
    },
  });

  return (
    <ShareDialogContext.Provider
      value={{
        emailInputRef,
        fileName,
        connection,
        selectedEmails,
        setSelectedEmails,
        permission,
        setPermission,
        isCopied,
        setIsCopied,
        isSharing,
        shareMutation,
        allEmailsValid,
        onClose,
      }}>
      {children}
    </ShareDialogContext.Provider>
  );
};

export const useShareFileDialog = (): ShareDialogContextValue => {
  const context = useContext(ShareDialogContext);

  if (!context) {
    throw new Error('useShareFileDialog must be used within a ShareDialogProvider');
  }

  return context;
};
