import type { RelaySliceItem, WritableRelaysState } from '../types';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

export const createRelay = {
  reducer: (
    state: WritableRelaysState,
    action: PayloadAction<RelaySliceItem>,
  ) => {
    state[action.payload.id] = action.payload;
  },
  prepare: (payload: Partial<RelaySliceItem>) => {
    const relay: RelaySliceItem = {
      id    : payload?.id || uuid(),
      peer  : payload.peer,
      source: payload.source,
    };

    return { payload: relay };
  },
};
