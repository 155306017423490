import { useCallback } from 'react';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogIcon,
  DialogTitle,
  Link,
  Spacer,
  Stack,
  Tooltip,
  Typography,
} from '@mtb/ui';
import { Trans, useTranslation } from '../../services/i18n';

/**
 * @param {Object} props
 * @param {() => void} props.onReopenProject Callback to reopen the project in the web app
 * @param {boolean} props.open Whether the dialog is open
 * @param {(tryAgain?: boolean) => void} props.onClose Callback to close the dialog
 */
export function NewOpenInDesktopDialog({ open, onClose, onReopenProject }) {
  const [t] = useTranslation();

  const handleOnReopenProject = useCallback(() => {
    onReopenProject();
    onClose(false);
  }, [onClose, onReopenProject]);
  const handleOnClose = useCallback(() => onClose(false), [onClose]);

  return (
    <Dialog
      data-testid="open-in-desktop-dialog"
      maxWidth="sm"
      open={open}
      onClose={handleOnClose}>
      <Stack direction="row">
        <DialogIcon severity="info" />
        <Stack
          direction="column"
          width="100%">
          <DialogTitle>
            {t('connection.openInDesktopAppDialog.title')}
            <Spacer />
            <Tooltip
              placement="bottom"
              title={t('connection.close')}>
              <Button
                color="default"
                icon={<CloseIcon />}
                variant="text"
                onClick={handleOnClose} />
            </Tooltip>
          </DialogTitle>
          <DialogContent>
            <Stack gap={2}>
              <Typography>
                <Trans
                  components={[
                    <Link
                      href="https://support.minitab.com/installation/frequently-asked-questions/other/determine-latest-version-desktop-software/"
                      rel="noopener noreferrer"
                      sx={{
                        display      : 'inline',
                        verticalAlign: 'inherit',
                        whiteSpace   : 'normal',
                      }}
                      target="_blank" />,
                  ]}
                  i18nKey="connection.openInDesktopAppDialog.installationDescription" />
              </Typography>
              <Typography>
                <Typography>
                  {t('connection.openInDesktopAppDialog.downloadDescription')}
                </Typography>
                <Link
                  href="https://www.minitab.com/support/downloads/"
                  rel="noopener noreferrer"
                  sx={{
                    width         : 'min-content',
                    justifyContent: 'flex-start',
                    verticalAlign : 'inherit',
                  }}
                  target="_blank">
                  {t('connection.openInDesktopAppDialog.downloadLinkText')}
                </Link>
              </Typography>
              <Typography>
                {t('connection.openInDesktopAppDialog.reopenDescription')}
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleOnReopenProject}>
              {t('connection.reopenInWebApp')}
            </Button>
            <Button
              color="default"
              variant="contained"
              onClick={handleOnClose}>
              {t('connection.close')}
            </Button>
          </DialogActions>
        </Stack>
      </Stack>
    </Dialog>
  );
}

export default NewOpenInDesktopDialog;
