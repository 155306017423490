import config from './config';

/**
 * The maximum number of planes that can be created.
 */
export const MAX_NUMBER_OF_PLANES = 10;

/**
 * The module that is considered the "root" and will be the default `currentPlaneId`.
 */
export const ROOT_MODULE_KEY = config.feature_flag_msso_root ? 'MSSO' : 'PLATFORM';
