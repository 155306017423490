import type { InterruptRecursion, StateFromReducer } from '../redux-helpers/types';
import type { UnknownAction } from '@reduxjs/toolkit';
import { combineReducers, createAction } from '@reduxjs/toolkit';
import layout from './layout';
import planes from './planes';
import relays from './relays';
import snackbar from './snackbar';
import * as user from './user';

const globalActions = {
  '@RESET_STORE': createAction('@RESET_STORE'),
} as const;

const actions = {
  ...globalActions,
  ...layout.actions,
  ...user.actions,
  ...planes.actions,
  ...snackbar.actions,
  ...relays.actions,
} as const;

const asyncActions = {
  ...planes.asyncActions,
  ...user.asyncActions,
} as const;

const combinedReducers = combineReducers({
  layout  : layout.reducer,
  user    : user.reducer,
  planes  : planes.reducer,
  snackbar: snackbar.reducer,
  relays  : relays.reducer,
});

export type RawPlatformStoreState = StateFromReducer<typeof combinedReducers & InterruptRecursion>;

const reducer = ((state: RawPlatformStoreState | undefined, action: UnknownAction) => {
  if (action.type === globalActions['@RESET_STORE'].type) {
    return combinedReducers(undefined, { type: '' });
  }
  return combinedReducers(state, action);
}) as typeof combinedReducers;

export { actions, asyncActions, reducer };
