import { Alert, AlertContent, AlertIcon, Collapse } from '@mtb/ui';
import { useTranslation } from '../../../services/i18n';
import { useShareFileDialog } from '../context';

export const CopyAlert = () => {
  const [t] = useTranslation();
  const { isCopied } = useShareFileDialog();

  return (
    // @ts-expect-error-next-line: Bad MtbUI Types */}
    <Collapse in={isCopied}>
      {/* @ts-expect-error-next-line: Bad MtbUI Types */}
      <Alert severity="success">
        <AlertIcon />
        {/* @ts-expect-error-next-line: Bad MtbUI Types */}
        <AlertContent>
          {t('connection.linkCopied')}
        </AlertContent>
      </Alert>
    </Collapse>
  );
};
