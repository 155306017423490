import { useCallback } from 'react';
import {
  Button,
  ShareIcon,
} from '@mtb/ui';
import DialogClient from '../../../clients/dialog';
import { GOOGLE_DRIVE, STORAGE_PROVIDER_KEYS } from '../../../constants';
import { useProvider } from '../../../hooks';
import configStore from '../../../services/config';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';

export const StorageProjectMenuShareButton = () => {
  const [t] = useTranslation();
  const { project, isCloudItemOwnedByMe } = useStorageProjectMenu();
  const { scopes } = useProvider(project.connection.type);

  const handleOnClick = useCallback(
    async () => {
      await DialogClient.shareFile(project.name, project.connection);
    },
    [project.name, project.connection],
  );

  if (
    !isCloudItemOwnedByMe ||
    !project.hasConnection ||
    !project.canInteractWithCloudProvider ||
    (
      project.connection.type === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE &&
      (
        // TODO: cleanup scope checking after SCOPES_V2 is the default
        !configStore.config.feature_flag_google_file_sharing ||
        !GOOGLE_DRIVE.SCOPES_V2.split(' ').every(scope => scopes.includes(scope))
      )
    )
  ) {
    return;
  }

  return (
    // @ts-expect-error-next-line - Bad MtbUI Types
    <Button
      variant="contained"
      onClick={handleOnClick}>
      {/* @ts-expect-error-next-line - Bad MtbUI Types */}
      <ShareIcon />
      {t('connection.shareFile')}
    </Button>
  );
};
