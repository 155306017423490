import type { AsyncPlaneThunkState } from '../types';
import CloudStorageClient from '../../../../../clients/cloud-storage';
import ModuleClient from '../../../../../clients/module';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { PLANE_HEALTH_TYPES, PLANE_STATUS_TYPES } from '../constants';

export const checkPlaneHealth = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/checkPlaneHealth',
  async (planeId: string, { requestId, getState }) => {
    const storePlane = getState().planes[planeId];
    if (!storePlane) {
      return;
    }

    const isCurrentRequest = requestId === storePlane.requestId;
    const isUnhealthy = storePlane.health === PLANE_HEALTH_TYPES.UNHEALTHY;
    const isErrored = storePlane.status === PLANE_STATUS_TYPES.ERROR || storePlane.error !== undefined;
    const shouldCheckHealth = isCurrentRequest && !isUnhealthy && !isErrored;
    if (!shouldCheckHealth) {
      return;
    }

    const checks: Array<Promise<boolean>> = [];
    checks.push(
      ModuleClient.Events.pulse(storePlane)
        .then(() => true)
        .catch(() => false),
    );
    if (storePlane.cloudStorageId) {
      checks.push(CloudStorageClient.healthCheckProject(storePlane.cloudStorageId));
    }

    const results = await Promise.all(checks);
    const moduleHealth = results[0];
    const storageHealth = storePlane.cloudStorageId ? results[1] : true;

    if (moduleHealth && storageHealth) {
      return PLANE_HEALTH_TYPES.HEALTHY;
    }

    if (storePlane.cloudStorageId && storageHealth) {
      return PLANE_HEALTH_TYPES.DISCONNECTED;
    }

    const isRecoverable =
    storePlane.cloudStorageId && await CloudStorageClient.getIsProjectRecoverable(storePlane.cloudStorageId);
    if (isRecoverable) {
      return PLANE_HEALTH_TYPES.RECOVERABLE;
    }

    return PLANE_HEALTH_TYPES.UNHEALTHY;
  },
);
