import type { ActionsMap, MessageHandler } from './types';

/**
 * @class PlaneChannel
 * @description
 * Represents a two-way communication channel between planes.
 */
export class PlaneChannel {
  #planeId: string;
  #peerPlaneId: string;
  #actions: ActionsMap = {};
  #messageHandler: MessageHandler | null = null;
  #onConnectedCallback: ((channel: PlaneChannel) => void) | null = null;

  /**
   * Creates an instance of PlaneChannel.
   * @param {string} planeId - The ID of this plane.
   * @param {string} peerPlaneId - The ID of the peer plane.
   */
  constructor(planeId: string, peerPlaneId: string) {
    this.#planeId = planeId;
    this.#peerPlaneId = peerPlaneId;
  }

  /**
   * Gets the ID of this plane.
   */
  get planeId(): string {
    return this.#planeId;
  }

  /**
   * Gets the ID of the peer plane.
   */
  get peerPlaneId(): string {
    return this.#peerPlaneId;
  }

  /**
   * Gets the actions associated with this channel.
   */
  get actions(): ActionsMap {
    return this.#actions;
  }

  /**
   * Sets the actions (methods) that this channel can perform.
   * @param {ActionsMap} actions - An object mapping action names to functions.
   */
  setActions(actions: ActionsMap): void {
    this.#actions = actions;
  }

  /**
   * Sets the message handler function to process incoming messages.
   * @param {MessageHandler} handler - A function to handle incoming messages.
   */
  setMessageHandler(handler: MessageHandler): void {
    this.#messageHandler = handler;
  }

  /**
   * Sends a message to the peer plane.
   * @param type - The type of the message.
   * @param payload - The payload of the message.
   * @returns A promise that resolves with the response from the peer.
   */
  async send(type: string, payload: Record<string, unknown> = {}): Promise<unknown> {
    return this.#messageHandler?.(type, payload);
  }

  /**
   * Connects this channel to a peer PlaneChannel instance.
   * @param {PlaneChannel} channel - The peer PlaneChannel instance to connect with.
   */
  connect(channel: PlaneChannel): void {
    this.#onConnectedCallback?.(channel);
  }

  /**
   * Sets a callback to execute when the channel is connected to a peer.
   * @param {(channel: PlaneChannel) => void} callback - The callback function.
   */
  onConnected(callback: (channel: PlaneChannel) => void): void {
    this.#onConnectedCallback = callback;
  }
}

export default PlaneChannel;
