import type { UseCloudExplorerItemReturnProps } from '../../../../../hooks';
import type { StorageExplorerColumn } from '../../../constants';
import type { StorageProviderItem } from '@';
import { TableCell } from '@mtb/ui';
import { useTable } from '../../../context';
import { CloudExplorerTableRowCellSkeleton } from './CloudExplorerTableRowCellSkeleton';

type CloudExplorerTableRowCellProps = {
  row: StorageProviderItem & UseCloudExplorerItemReturnProps;
  column: StorageExplorerColumn;
};
export function CloudExplorerTableRowCell({
  row,
  column,
}: CloudExplorerTableRowCellProps) {
  const { isLoading } = useTable();

  return (
    <TableCell
      key={column.id}
      align={column.align}
      sx={{
        px: 2,
        py: column.paddingY ?? 1.5,
        ...(isLoading && { pointerEvents: 'none' }),
        ...(column.width && { width: column.width }),
      }}>
      <CloudExplorerTableRowCellSkeleton
        disabled={column.disableSkeleton}
        hasIcon={column.id === 'name'}>
        {column.render(row)}
      </CloudExplorerTableRowCellSkeleton>
    </TableCell>
  );
}
