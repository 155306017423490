import type { AppRailDrawerIds, LayoutState } from './types';
import { ROOT_MODULE_KEY } from '../../../../constants';
import { PLATFORM_APPRAIL_DRAWER_IDS } from './constants';

export const initialState: LayoutState = {
  loading         : false,
  interactive     : true,
  currentModuleKey: ROOT_MODULE_KEY,
  currentPlaneId  : undefined,
  maxPlanesDialog : false,
  appRailDrawers  : Object.fromEntries(
    Object.entries(PLATFORM_APPRAIL_DRAWER_IDS).map(([_, id]) => [id, false]),
  ) as Record<AppRailDrawerIds, boolean>,
};

export default initialState;
