import type {
  AlertDialogOptions,
  ConfirmDialogOptions,
  InitializeDialogServiceOptions,
} from './types';
import DialogsClient from '../../../clients/dialogs';
import LoggerClient from '../../../clients/logger';

class DialogsService {
  #logger = LoggerClient.createNamedLogger('DialogsService');

  initialize(handlers: InitializeDialogServiceOptions) {
    DialogsClient.initialize(handlers);
    this.#logger.log('Dialogs service initialized...');
  }

  async alert(options: AlertDialogOptions) {
    return await DialogsClient.showAlertDialog(options);
  }

  async confirm(options: ConfirmDialogOptions) {
    return await DialogsClient.showConfirmDialog(options);
  }
}

export default DialogsService;
