import type { SelectOptions } from './types';
import type { ModuleConfig } from '../../../types';
import ModuleClient from '../../../clients/module';
import config from '../../../config';

export function getModuleOptions(module: ModuleConfig, options: SelectOptions): Required<SelectOptions> {
  const defaultOptions: Required<SelectOptions> = {
    moduleKey: module.key,
    filter   : options.filter || module.storage?.passthroughFilter || [],
  };

  return getModuleOverride(defaultOptions, options);
}

function getModuleOverride(defaultOptions: Required<SelectOptions>, options: SelectOptions): Required<SelectOptions> {
  const moduleOptions = defaultOptions;
  switch (moduleOptions.moduleKey) {
    case ModuleClient.MSSO.key:
      if (config.feature_flag_open_project_files) {
        moduleOptions.filter = options.filter ?? [
          ...(ModuleClient.MSSO.storage?.filter ?? []),
          ...(ModuleClient.MSSO.storage?.passthroughFilter ?? []),
        ];
      }
      break;
    case ModuleClient.DASHBOARD.key:
      if (config.feature_flag_open_project_files && config.feature_flag_open_project_files_dashboard) {
        moduleOptions.filter = options.filter ?? [
          ...(ModuleClient.DASHBOARD.storage?.filter ?? []),
          ...(ModuleClient.DASHBOARD.storage?.passthroughFilter ?? []),
        ];
      }
      break;
    case ModuleClient.WSO.key:
      if (config.feature_flag_open_project_files && config.feature_flag_open_project_files_workspace) {
        moduleOptions.filter = options.filter ?? [
          ...(ModuleClient.WSO.storage?.filter ?? []),
          ...(ModuleClient.WSO.storage?.passthroughFilter ?? []),
        ];
      }
      break;
    case ModuleClient.BRAINSTORM.key:
      if (config.feature_flag_open_project_files && config.feature_flag_open_project_files_brainstorm) {
        moduleOptions.filter = options.filter ?? [
          ...(ModuleClient.BRAINSTORM.storage?.filter ?? []),
          ...(ModuleClient.BRAINSTORM.storage?.passthroughFilter ?? []),
        ];
      }
      break;
    default:
      break;
  }

  return moduleOptions;
}
