import type { AsyncRelaysThunkState } from '../types';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';

export const closeStaleRelays = createAsyncThunk.withTypes<AsyncRelaysThunkState>()(
  'relays/closeStaleRelays',
  (remainingPlaneIds: string[], { getState }) => {
    return Object.values(getState().relays).reduce((acc, relay) => {
      if (!relay?.source?.planeId && !relay?.peer?.planeId) {
        acc.push(relay.id);
        return acc;
      }

      let hasHealthyParty = false;
      hasHealthyParty ||= Boolean(relay.source?.planeId && remainingPlaneIds.includes(relay.source.planeId));
      hasHealthyParty ||= Boolean(relay.peer?.planeId && remainingPlaneIds.includes(relay.peer.planeId));

      if (!hasHealthyParty) {
        acc.push(relay.id);
      }

      return acc;
    }, [] as string[]);
  },
);
