import { createSlice } from '../../redux-helpers/create-slice';
import { closeStaleRelays, closeStaleRelaysReducer } from './close-stale-relays';
import createRelay from './create-relay';
import initialState from './initial-state';
import removeRelay from './remove-relay';
import { removeRelayPartiesReducer } from './remove-relay-parties';
import updateRelay from './update-relay';

const asyncActions = {
  closeStaleRelays,
};

const slice = createSlice({
  name    : 'relays',
  initialState,
  reducers: {
    createRelay,
    removeRelay,
    updateRelay,
  },
  extraReducers: builder => {
    closeStaleRelaysReducer(builder);
    removeRelayPartiesReducer(builder);
  },
});

const relays = {
  actions: slice.actions,
  reducer: slice.reducer,
  asyncActions,
} as const;

export default relays;

