import type { Relay } from '../../../sub-services/relay/types';
import type { RelaySliceItem } from '../../reducers/relays/types';
import { createSelector } from '../../redux-helpers/create-selector';

const selectors = {
  relay: createSelector([state => state.relays, (_state, id: string) => id], (relays, id) => {
    const storeRelay = relays[id];

    return storeRelay ? convertRelay(storeRelay) : undefined;
  }),
  relayByPartyId: createSelector([state => state.relays, (_state, id: string) => id], (relays, id) => {
    const storeRelay = Object.values(relays).find(r => r.source?.id === id || r.peer?.id === id);
    return storeRelay ? convertRelay(storeRelay) : undefined;
  }),
};

function convertRelay(storeRelay: RelaySliceItem): Relay {
  return {
    id    : storeRelay.id,
    source: storeRelay.source,
    peer  : storeRelay.peer,
  };
}

export default selectors;
