import type { ProviderKey, ProviderStoreSlice, StoreProvider } from './types';
import { CLOUD_STORAGE_KEY } from '../../constants';
import Storage from '../storage';

const STORAGE_PROVIDERS_KEY = 'mtb-cloud-storage-providers';


export class ProviderStore {
  #storage = new Storage();

  migrateOldStorage(): boolean {
    // check that the storage key exists
    let providerKey = localStorage.getItem(CLOUD_STORAGE_KEY);
    if (!providerKey) {
      return false;
    }

    providerKey = providerKey.slice(1, -1); // remove the quotes
    const providerValue = localStorage.getItem(providerKey);
    if (!providerValue) {
      return false;
    }

    const providerBag = JSON.parse(providerValue);
    const currentProvider = providerBag.current || '';
    const provider = providerBag[currentProvider];
    if (!provider) {
      return false;
    }

    this.setProvider(provider.type, {
      type   : provider.type,
      account: {
        id               : provider.account.id,
        email            : provider.account.email,
        name             : provider.account.name,
        avatar           : provider.account.picture,
        isInsightsEnabled: false,
      },
      tokens: {
        accessToken : provider.auth.access_token,
        refreshToken: provider.auth.refresh_token,
        expiresAt   : provider.auth.expires_at,
      },
      defaultSaveFolder: provider.autoSaveFolder ? {
        name    : provider.autoSaveFolder.name,
        id      : provider.autoSaveFolder.id,
        driveId : provider.autoSaveFolder.driveId,
        parentId: provider.autoSaveFolder.parentId,
        webUrl  : provider.autoSaveFolder.webUrl,
      } : null,
    });

    this.setDefaultProvider(provider.type);

    localStorage.removeItem(CLOUD_STORAGE_KEY);

    return true;
  }

  private getSlice(): ProviderStoreSlice {
    return (
      this.#storage.getItem(STORAGE_PROVIDERS_KEY) || {
        defaultProvider: null,
        providers      : {} as Record<ProviderKey, StoreProvider>,
      }
    );
  }

  private setSlice(slice: ProviderStoreSlice) {
    this.#storage.setItem(STORAGE_PROVIDERS_KEY, slice);
  }

  getProvider(key: ProviderKey): StoreProvider | null {
    const slice = this.getSlice();
    return slice.providers[key] || null;
  }

  setProvider(key: ProviderKey, provider: StoreProvider) {
    const slice = this.getSlice();
    slice.providers[key] = provider;
    this.setSlice(slice);
  }

  removeProvider(key: ProviderKey) {
    const slice = this.getSlice();
    delete slice.providers[key];
    this.setSlice(slice);
  }

  setDefaultProvider(key: ProviderKey) {
    const slice = this.getSlice();
    slice.defaultProvider = key;
    this.setSlice(slice);
  }

  getDefaultProvider(): ProviderKey | null {
    const slice = this.getSlice();
    return slice.defaultProvider || null;
  }

  removeDefaultProvider() {
    const slice = this.getSlice();
    slice.defaultProvider = null;
    this.setSlice(slice);
  }

  getProviders(): Record<ProviderKey, StoreProvider> {
    return this.getSlice().providers;
  }
}

const providerStorage = new ProviderStore();

export default providerStorage;
