import type { OnOpenReason } from '../constants';
import type { StorageProviderItem } from '@';
import { useCallback, useMemo } from 'react';
import { getStorageItemIcon } from '../../../utils';
import { useTable } from '../CloudExplorerTableContainer/CloudExplorerTable/context';
import { useCloudExplorerTable } from './useCloudExplorerTable';

export type UseCloudExplorerItemReturnProps = {
  name: string;
  icon: JSX.Element | null;
  isFolder: boolean;
  isSelected: boolean;
  select: () => void;
  open: (reason: OnOpenReason) => void;
};
/**
 * Custom hook for managing storage explorer items.
 * @param item - The storage item.
 * @returns An object containing the item passed in, more properties such as an icon, and functions for interaction.
 */
export const useCloudExplorerItem = (
  item: StorageProviderItem,
): StorageProviderItem & UseCloudExplorerItemReturnProps => {
  const { openItem } = useCloudExplorerTable();
  const { selected, setSelected } = useTable();

  const icon = useMemo(() => getStorageItemIcon(item), [item]);

  const isFolder = useMemo(() => Boolean(item?.folder), [item?.folder]);

  const isSelected = useMemo(
    () => selected?.id === item?.id,
    [selected?.id, item?.id],
  );

  const handleSetSelected = useCallback(() => {
    setSelected(item);
  }, [item, setSelected]);

  const handleOpenItem = useCallback((reason: OnOpenReason) => {
    openItem(item, reason);
  }, [item, openItem]);

  return {
    ...item,
    icon,
    isFolder,
    isSelected,
    select: handleSetSelected,
    open  : handleOpenItem,
  };
};
