import type { ModuleConfig } from '../types';
import config from '../../config';
import DATACENTER from '../datacenter';
import { RemoteDashboard, loadRemoteDashboard } from './remote';

const CONFIG: ModuleConfig = {
  key      : 'DASHBOARD',
  title    : 'minitabDashboards',
  icon     : 'DashboardsIcon',
  // Use `MSSO` productId since these are included if you have a subscription to Minitab Online.
  productId: 'e68147de1c46451bafadcfcc44e196cc',
  card     : {
    title         : 'minitabDashboards',
    description   : 'minitabDashboardsDescription',
    tagline       : 'dashboard',
    icon          : 'DashboardsIcon',
    heroIcon      : 'DashboardHeroIcon',
    fillColor     : '#C7A3BF',
    hoverFillColor: '#83286F',
  },
  preloadRemoteModule: loadRemoteDashboard,
  remoteModule       : RemoteDashboard,
  storage            : {
    defaultProjectName: 'minitabDashboardsDefaultProject',
    defaultExtension  : '.mdbx',
    defaultPlaneState : { isDirty: false },
    filter            : ['.mdbx'],
    passthroughFilter : config.feature_flag_dc_preview
    // we can't add dashboard to xlsx yet because datacenter can't export multiple mwx's from a single xlsx yet
      ? config.feature_flag_dashboard_excel
        ? (DATACENTER.storage?.passthroughFilter as string[])
        : (DATACENTER.storage?.passthroughFilter as string[]).filter(ext => ext !== '.xlsx')
      : ['.mtw', '.mwx'],
  },
};

export default CONFIG;
