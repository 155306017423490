import type { RelaysSlice } from '../types';
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { removeRelayParties } from './thunk';

export const removeRelayPartiesReducer = (builder: ActionReducerMapBuilder<RelaysSlice>) => {
  return (
    builder
      .addCase(removeRelayParties.pending, () => undefined)
      .addCase(removeRelayParties.fulfilled, (state, action) => {
        const planeId = action.payload;
        for (const relay of Object.values(state)) {
          const isPeer = relay.peer?.planeId === planeId;
          const isSource = relay.source?.planeId === planeId;
          if (!isPeer && !isSource) {
            continue;
          }

          if (isPeer) {
            relay.peer = undefined;
          }

          if (isSource) {
            relay.source = undefined;
          }

          if (!relay.peer && !relay.source) {
            delete state[relay.id];
          }
        }
      })
      .addCase(removeRelayParties.rejected, () => undefined)
  );
};
