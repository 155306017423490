import { useState } from 'react';
import { Divider, useEventCallback } from '@mtb/ui';
import { LoadingButton, ProjectCheckoutStatus } from '../..';
import { useCloudStorageProject, useCloudStorageStore } from '../../../hooks';
import { useTranslation } from '../../../services/i18n';
import { useStorageProjectMenu } from '../hooks';

export function StorageProjectMenuReadonlyInfo() {
  const [t] = useTranslation();
  const { projectId, onReopenProject } = useStorageProjectMenu();
  const project = useCloudStorageProject(projectId);
  const cloudStorage = useCloudStorageStore();
  const [isTryingReopen, setIsTryingReopen] = useState(false);

  const handleOnEditButtonClick = useEventCallback(async () => {
    setIsTryingReopen(true);
    const checkoutUser = await project.getCheckoutUser();
    if (!checkoutUser) {
      onReopenProject();
    } else {
      await cloudStorage.reopenProjectFailed({ projectId, onReopenProject });
    }
    setIsTryingReopen(false);
  }, [
    cloudStorage,
    setIsTryingReopen,
    onReopenProject,
    project.driveId,
    project.itemId,
  ]);

  return (
    <>
      <ProjectCheckoutStatus projectId={projectId} />
      <LoadingButton
        loading={isTryingReopen}
        onClick={handleOnEditButtonClick}>
        {t('connection.edit')}
      </LoadingButton>
      <Divider />
    </>
  );
}
