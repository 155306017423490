import { useEffect } from 'react';
import { useShareFileDialog } from '../context';

const ALERT_TIMEOUT = 5 * 1000;

export const useCopiedAlertTimeout = () => {
  const { isCopied, setIsCopied } = useShareFileDialog();

  useEffect(
    () => {
      const copyAlertTimer = setTimeout(() => setIsCopied(false), ALERT_TIMEOUT);
      return () => clearTimeout(copyAlertTimer);
    },
    [isCopied, setIsCopied],
  );
};
