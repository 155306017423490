import type { StorageProviderKey } from '../../../../../types';
import { useCallback, useMemo } from 'react';
import { Link } from '@mtb/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import DialogClient from '../../../../clients/dialog';
import ProviderClient from '../../../../clients/provider';
import { STORAGE_PROVIDER_KEYS } from '../../../../constants';
import { useTranslation } from '../../../../services/i18n';
import ProjectStore from '../../../../store/projects';
import { getRootFolderName } from '../../../../utils';
import { useStorageProjectMenu } from '../../hooks';

type MutationArgs = {
  type: StorageProviderKey;
  itemId: string;
  driveId: string;
  destinationId: string;
  destinationName: string;
}

export function StorageProjectMenuMoveFile({ setIsMoving }: { setIsMoving: (isMoving: boolean) => void }) {
  const [t] = useTranslation();
  const { project } = useStorageProjectMenu();
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ['project-menu', 'parent-folder-name', project.id], [project.id]);

  const moveFileMutation = useMutation({
    mutationFn: ({ type, itemId, driveId, destinationId }: MutationArgs) => {
      setIsMoving(true);
      return ProviderClient.moveItem(type, driveId, itemId, destinationId);
    },
    onSuccess: async (updatedItem, { destinationName }) => {
      const oldProject = ProjectStore.getProject(project.id);

      if (!oldProject) {
        return;
      }

      ProjectStore.setProject(project.id, {
        ...oldProject,
        parentFolderUrl: updatedItem.parentFolderUrl,
      });

      // Update the parent folder text and url at the same time to ensure consistency.
      // setQueryData could (and ideally should) be used within `onMutation`, but we don't always have the URL at that time leading to a desync between the name and URL.
      // DialogClient.selectFolder would need to be updated to return the StorageProviderItem of the root folder rather than null to fix this.
      queryClient.setQueryData(queryKey, destinationName);
    },
    onSettled: () => {
      setIsMoving(false);
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const handleOnClick = useCallback(
    async () => {
      const selection = await DialogClient.selectFolder(project.connection.type);

      if (typeof selection === 'boolean') {
        return;
      }

      try {
        await moveFileMutation.mutateAsync({
          type   : project.connection.type,
          itemId : project.connection.itemId as string,
          driveId: project.connection.driveId as string,
          ...(selection === null ? {
            destinationId  : 'root',
            destinationName: getRootFolderName(project.connection.type),
          } : {
            destinationId  : selection.id,
            destinationName: selection.name,
          }),
        });
      } catch (error) {
        console.error(error);
      }
    },
    [project, moveFileMutation],
  );

  if (project.connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
    return null;
  }

  return (
    // @ts-expect-error-next-line - Bad MtbUI types
    <Link onClick={handleOnClick}>
      {t('connection.move')}
    </Link>
  );
}
