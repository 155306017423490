import type { DataSyncRelay } from './types';
import type { DataItem } from '../../../clients/data/types';
import type { GenerateDataResponse } from '../../remote-module-config/types';
import type { RelayPartyInfo } from '../../sub-services/relay/types';
import ModuleClient from '../../../clients/module';
import RelaySubService from '../../sub-services/relay';
import { getDataSyncRelay } from './actions';

class DataSyncService {
  createOrConnect(parties: { source: RelayPartyInfo; peer: RelayPartyInfo }): DataSyncRelay {
    if (!parties.peer && !parties.source) {
      throw new Error('Source or peer must be provided');
    }

    const relay = RelaySubService.createOrConnect(parties);

    return getDataSyncRelay(relay);
  }

  disconnect(partyId: string): void {
    RelaySubService.disconnect(partyId);
  }

  getDataSync(partyId: string): DataSyncRelay | undefined {
    const relay = RelaySubService.getRelayByParty(partyId);

    return relay ? getDataSyncRelay(relay) : undefined;
  }

  useDataSync(partyId: string): DataSyncRelay | undefined {
    const relay = RelaySubService.useRelay(partyId);

    return relay ? getDataSyncRelay(relay) : undefined;
  }

  generateData(data: DataItem, steps: unknown): Promise<GenerateDataResponse> {
    return ModuleClient.Actions.DataSync.generateData(data, steps);
  }
}

export default DataSyncService;
