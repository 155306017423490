import type { CloudStorageProject, Connection, ConnectionInfo } from './types';
import type { DeepPartial } from './utils';
import type {
  CloudStorageCreateProjectResponse,
  CloudStoragePassthroughItem,
  StorageProviderCheckoutUser,
  StorageProviderItem,
  StorageProviderItemConnection,
  StorageProviderKey,
} from '@';
import type { StoreProject } from 'lib/store/projects/types';
import { deepMerge } from '@mtb/utilities';
import CloudStorageApi from '../../api';
import DialogClient from '../../clients/dialog';
import ProviderClient from '../../clients/provider';
import {
  AUTO_SAVE_STATUS,
  CLOUD_STATUS,
  CONNECTION_STATUS,
  MINIMUM_STORAGE_SPACE_THRESHOLD,
  STORAGE_PROVIDER_KEYS,
} from '../../constants';
import { CLOUD_STORAGE_ERRORS, CloudStorageError } from '../../errors/cloud-storage-error';
import ProjectStore from '../../store/projects';
import ProviderStore from '../../store/providers';
import {
  canPlatformOpenInDesktop,
  downloadFileUrl,
  getNameParts,
  getProjectDesktopFileScheme,
} from '../../utils';
import { getDefaultSaveProvider, toExternalProject } from './utils';

class ProjectService {
  async verifyStorageSpace(type: StorageProviderKey): Promise<void> {
    const remainingStorageSpace = await ProviderClient.getRemainingStorageSpace(
      type,
    );
    if (remainingStorageSpace < MINIMUM_STORAGE_SPACE_THRESHOLD) {
      DialogClient.alertOutOfStorage();
      throw new Error('Not enough storage space.');
    }
  }

  async createProject(file: File): Promise<CloudStorageProject> {
    if (!file.name) {
      throw new Error('File name is required.');
    }

    const provider = getDefaultSaveProvider();
    if (provider) {
      await this.verifyStorageSpace(provider.type);
      const saveLocationValid = await this.verifyAutoSaveFolder(provider.type);
      if (!saveLocationValid) {
        CloudStorageError.Throw('Invalid save location', CLOUD_STORAGE_ERRORS.INVALID_SAVE_LOCATION);
      }
    }

    let item: StorageProviderItemConnection | undefined;
    let connection: Connection | undefined;

    if (provider) {
      item = await ProviderClient.createItem(provider.type, file.name);
      if (!item) {
        throw new Error('Failed to create item.');
      }

      connection = {
        itemId      : item.id,
        driveId     : item.driveId,
        type        : provider?.type,
        accessToken : provider?.tokens.accessToken,
        refreshToken: provider?.tokens.refreshToken,
      };
    }

    const projectInfo = await CloudStorageApi.createProject(connection);
    if (!projectInfo) {
      throw new Error('Failed to create project.');
    }

    const nameParts = getNameParts(item?.name || file.name);

    const project: StoreProject = {
      id             : projectInfo.id,
      name           : nameParts.name,
      displayName    : nameParts.displayName,
      extension      : nameParts.extension,
      parentFolderUrl: item?.parentFolderUrl,
      connection     : {
        type          : provider?.type || STORAGE_PROVIDER_KEYS.LOCAL,
        itemId        : item?.id,
        driveId       : item?.driveId,
        autoSaveStatus: projectInfo.autoSaveStatus,
        cloudStatus   : projectInfo.cloudStatus,
      },
      operation: null,
    };

    ProjectStore.setProject(project.id, project);

    let upload: Promise<boolean> | undefined;
    let checkout: Promise<boolean> | undefined;

    if (file.size > 0) {
      upload = CloudStorageApi.uploadProjectContent(project.id, file);
    }

    if (item) {
      checkout = this.checkOutProject(project.id, false);
    }

    await Promise.all([upload, checkout]);

    const finalProject = ProjectStore.getProject(project.id);
    if (!finalProject) {
      throw new Error('Failed to create project.');
    }

    return toExternalProject(finalProject);
  }

  async openProject(
    item: StorageProviderItem,
    overrideLock = false,
  ): Promise<CloudStorageProject> {
    const provider = ProviderStore.getProvider(item.type);
    if (!provider) {
      throw new Error('Provider not found.');
    }

    const connection: Connection = {
      itemId      : item.id,
      driveId     : item.driveId,
      type        : provider.type,
      accessToken : provider.tokens.accessToken,
      refreshToken: provider.tokens.refreshToken,
    };

    // TODO: Fix these types
    const createRespose: CloudStorageCreateProjectResponse | boolean =
      await CloudStorageApi.openProjectFromConnection(connection);

    if (createRespose instanceof Boolean && createRespose === false) {
      throw new Error('Failed to open project.');
    }

    const projectResponse = createRespose as CloudStorageCreateProjectResponse;

    const nameParts = getNameParts(item.name);

    const project: StoreProject = {
      id             : projectResponse.id,
      name           : nameParts.name,
      displayName    : nameParts.displayName,
      extension      : nameParts.extension,
      parentFolderUrl: item.parentFolderUrl,
      connection     : {
        type          : provider.type,
        itemId        : item.id,
        driveId       : item.driveId,
        autoSaveStatus: projectResponse.autoSaveStatus,
        cloudStatus   : projectResponse.cloudStatus,
      },
      operation: null,
    };

    ProjectStore.setProject(project.id, project);

    await this.checkOutProject(project.id, overrideLock);

    const finalProject = ProjectStore.getProject(project.id);
    if (!finalProject) {
      throw new Error('Failed to open project.');
    }

    return toExternalProject(finalProject);
  }

  async createProjectConnection(
    projectId: string,
    providerKey?: StorageProviderKey,
  ): Promise<boolean> {
    const provider = providerKey
      ? ProviderStore.getProvider(providerKey)
      : getDefaultSaveProvider();
    if (!provider) {
      return false;
    }

    const saveLocationValid = await this.verifyAutoSaveFolder(provider.type);
    if (!saveLocationValid) {
      DialogClient.alertInvalidSaveLocation();
      return false;
    }

    const project = ProjectStore.getProject(projectId);
    if (!project) {
      return false;
    }

    await this.verifyStorageSpace(provider.type);

    const item = await ProviderClient.createItem(provider.type, project.name);

    const connection: Connection = {
      itemId      : item.id,
      driveId     : item.driveId,
      type        : provider.type,
      accessToken : provider.tokens.accessToken,
      refreshToken: provider.tokens.refreshToken,
    };

    await CloudStorageApi.setProjectConnection(projectId, connection);

    const nameParts = getNameParts(item.name);

    const updatedProject: StoreProject = {
      ...project,
      name           : nameParts.name,
      displayName    : nameParts.displayName,
      parentFolderUrl: item.parentFolderUrl,
      connection     : {
        ...project.connection,
        type   : provider.type,
        itemId : item.id,
        driveId: item.driveId,
      },
    };

    ProjectStore.setProject(projectId, updatedProject);

    await this.checkOutProject(project.id, false);

    return true;
  }

  async verifyAutoSaveFolder(providerKey: StorageProviderKey): Promise<boolean> {
    const provider = ProviderStore.getProvider(providerKey);
    if (!provider) {
      throw new Error('Provider not found.');
    }

    // No default selected, root will be used
    if (!provider.defaultSaveFolder) {
      return true;
    }

    const valid = await ProviderClient.verifyAutoSaveFolder({
      type   : provider.type,
      itemId : provider.defaultSaveFolder.id,
      driveId: provider.defaultSaveFolder.driveId,
    });

    if (!valid) {
      ProviderStore.setProvider(providerKey, {
        ...provider,
        defaultSaveFolder: null,
      });
    }

    return valid;
  }

  private getProjectInternal(projectId: string): StoreProject {
    const project = ProjectStore.getProject(projectId);
    if (!project) {
      throw new Error('Project not found.');
    }

    return project;
  }

  getProject(projectId: string): CloudStorageProject {
    return toExternalProject(this.getProjectInternal(projectId));
  }

  updateProject(id: string, updates: DeepPartial<StoreProject>): void {
    const project = this.getProjectInternal(id);
    const updatedProject = deepMerge(project, updates) as StoreProject;
    ProjectStore.setProject(id, updatedProject);
  }

  closeProject(projectId: string): void {
    ProjectStore.removeProject(projectId);
  }

  async checkOutProject(
    projectId: string,
    overrideLock = false,
  ): Promise<boolean> {
    const project = this.getProjectInternal(projectId);

    let checkedOut = await CloudStorageApi.checkOutProject(projectId, false);
    const isGoogle =
      project.connection.type === STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE;
    if (!checkedOut && isGoogle && overrideLock) {
      if (await DialogClient.confirmOverrideLock()) {
        checkedOut = await CloudStorageApi.checkOutProject(projectId, true);
      }
    }

    this.updateProject(projectId, {
      connection: {
        cloudStatus   : checkedOut ? CLOUD_STATUS.OWNED : CLOUD_STATUS.READONLY,
        autoSaveStatus: checkedOut
          ? AUTO_SAVE_STATUS.STARTED
          : project.connection.autoSaveStatus,
      },
    });

    return checkedOut;
  }

  async syncProjectInfo(projectId: string): Promise<boolean> {
    try {
      const info = await CloudStorageApi.getProjectInfo(projectId);
      if (!info) {
        return false;
      }

      this.updateProject(projectId, {
        connection: {
          autoSaveStatus: info.autoSaveStatus,
          cloudStatus   : info.cloudStatus,
        },
      });

      return true;
    } catch {
      return false;
    }
  }

  async renameProject(projectId: string, name: string): Promise<boolean> {
    const project = this.getProjectInternal(projectId);

    const originalNameParts = getNameParts(project.name);

    const newNameParts = getNameParts(name);
    this.updateProject(projectId, newNameParts);

    if (project.connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
      return true;
    }

    const newName = await ProviderClient.renameItem(project.connection, name);
    const success = Boolean(newName);

    const updatedNameParts = success
      ? getNameParts(newName)
      : originalNameParts;

    this.updateProject(projectId, updatedNameParts);

    return success;
  }

  async toggleAutoSave(projectId: string): Promise<boolean> {
    const project = this.getProjectInternal(projectId);

    if (project.connection.autoSaveStatus === AUTO_SAVE_STATUS.STARTED) {
      return await this.disableAutoSave(projectId);
    }

    return await this.enableAutoSave(projectId);
  }

  async saveToProvider(
    projectId: string,
    provider: StorageProviderKey,
  ): Promise<boolean> {
    const project = this.getProjectInternal(projectId);

    if (project.connection.type !== STORAGE_PROVIDER_KEYS.LOCAL) {
      return false;
    }

    this.updateProject(projectId, {
      operation: 'saving',
    });

    const didCreate = await this.createProjectConnection(projectId, provider);

    this.updateProject(projectId, {
      operation: null,
    });

    return didCreate;
  }

  async enableAutoSave(projectId: string): Promise<boolean> {
    const project = this.getProjectInternal(projectId);

    const existsInCloud =
      project.connection.type !== STORAGE_PROVIDER_KEYS.LOCAL;

    // If the project is not connected. create a connection to the
    // new provider before enabling auto-save.
    if (!existsInCloud) {
      const didCreate = await this.createProjectConnection(projectId);
      if (!didCreate) {
        return false;
      }
    }

    const isOwned = project.connection.cloudStatus === CLOUD_STATUS.OWNED;
    if (!existsInCloud || !isOwned) {
      return await this.checkOutProject(projectId, true);
    }

    const autoSaveEnabled = await CloudStorageApi.setProjectAutoSave(
      projectId,
      AUTO_SAVE_STATUS.STARTED,
    );

    if (!autoSaveEnabled) {
      return false;
    }

    this.updateProject(projectId, {
      connection: {
        autoSaveStatus: AUTO_SAVE_STATUS.STARTED,
      },
    });

    return true;
  }

  async disableAutoSave(projectId: string): Promise<boolean> {
    const successfullyDisabled = await CloudStorageApi.setProjectAutoSave(
      projectId,
      AUTO_SAVE_STATUS.PAUSE_ON_NEXT_SAVE,
    );

    if (!successfullyDisabled) {
      return false;
    }

    this.updateProject(projectId, {
      connection: {
        autoSaveStatus: AUTO_SAVE_STATUS.NONE,
      },
    });

    return true;
  }

  async flushProject(projectId: string): Promise<boolean> {
    const project = this.getProjectInternal(projectId);

    if (project.connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
      return true;
    }

    return await CloudStorageApi.flushProject(projectId);
  }

  downloadProject(projectId: string): void {
    const project = this.getProjectInternal(projectId);
    const url = CloudStorageApi.getProjectDownloadUrl(projectId, project.name);
    downloadFileUrl(url, project.name);
  }

  async createPassthroughItem(
    file: File,
  ): Promise<CloudStoragePassthroughItem> {
    const { name, extension, displayName } = getNameParts(file?.name);
    if (!name) {
      throw new Error('Item name is required.');
    }

    if (!extension) {
      throw new Error('Item name must include the file extension.');
    }

    const passthroughId = await CloudStorageApi.openPassthroughItemFromFile(
      file,
    );

    if (typeof passthroughId === 'boolean') {
      throw new Error('Failed to create passthrough item.');
    }

    return { projectId: passthroughId, name, displayName, extension };
  }

  async openPassthroughItem(
    item: StorageProviderItem,
  ): Promise<CloudStoragePassthroughItem> {
    const { name, extension, displayName } = getNameParts(item?.name);
    if (!name) {
      throw new Error('Item name is required.');
    }

    if (!extension) {
      throw new Error('Item name must include the file extension.');
    }

    const provider = ProviderStore.getProvider(item.type);
    if (!provider) {
      throw new Error('Provider not found.');
    }

    if (!provider.tokens) {
      throw new Error('Provider tokens not found.');
    }

    const passthroughId =
      await CloudStorageApi.openPassthroughItemFromConnection({
        type        : item.type,
        itemId      : item.id,
        driveId     : item.driveId,
        accessToken : provider.tokens.accessToken,
        refreshToken: provider.tokens.accessToken,
      });

    if (typeof passthroughId === 'boolean') {
      throw new Error('Failed to open passthrough item.');
    }

    return { projectId: passthroughId, name, displayName, extension };
  }

  /**
   * Downloads the passthrough item.
   * @param passthroughItem - The passthrough item to download.
   * @returns A promise that resolves with the downloaded passthrough item blob.
   */
  async downloadPassthrough(passthroughItem: string): Promise<Blob> {
    return await CloudStorageApi.downloadPassthrough(passthroughItem);
  }

  verifyBeforeOpen(item: ConnectionInfo): boolean {
    if (item.type === STORAGE_PROVIDER_KEYS.LOCAL) {
      return true;
    }

    const provider = ProviderStore.getProvider(item.type);
    if (!provider) {
      throw new Error('Provider not found.');
    }

    if (!provider.tokens) {
      throw new Error('Provider tokens not found.');
    }

    const projectExists = Object.values(ProjectStore.getProjects()).some(
      (p) => {
        return (
          p.connection.type === item.type &&
          p.connection.itemId === item.itemId &&
          p.connection.driveId === item.driveId
        );
      },
    );

    if (projectExists) {
      DialogClient.alertAlreadyOpen();
      return false;
    }

    return true;
  }

  async getCheckoutUser(
    projectId: string,
  ): Promise<StorageProviderCheckoutUser | null> {
    const project = this.getProjectInternal(projectId);
    return await ProviderClient.getCheckoutUser?.(project.connection);
  }

  async isRecoverable(projectId: string): Promise<boolean> {
    try {
      const project = ProjectStore.getProject(projectId);
      if (!project || project.connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
        return false;
      }

      const item = await ProviderClient.getItem(project.connection);
      return Boolean(item);
    } catch {
      return false;
    }
  }

  async recoverProject(projectId: string): Promise<CloudStorageProject> {
    const existingProject = ProjectStore.getProject(projectId);
    if (!existingProject) {
      throw new Error('Existing Project does not exist.');
    }

    if (existingProject.connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
      throw new Error('Project is not recoverable.');
    }

    const item = await ProviderClient.getItem(existingProject.connection);
    if (!item) {
      throw new Error('Project item does not exist.');
    }

    this.closeProject(projectId);

    return await this.openProject(item, false);
  }

  async reopenProject(
    projectId: string,
    events: { reopenProject: () => Promise<void> },
  ): Promise<void> {
    const project = this.getProjectInternal(projectId);
    if (project.connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
      return;
    }

    if (project.connection.cloudStatus === CLOUD_STATUS.OWNED) {
      return;
    }

    try {
      this.updateProject(projectId, {
        operation: CONNECTION_STATUS.CHECKING_OUT,
      });
      const checkedOut = await this.checkOutProject(projectId, false);
      if (!checkedOut) {
        DialogClient.reopenProjectFailed(() => {
          return this.reopenProject(projectId, events);
        });
        return;
      }
      await events.reopenProject?.();
    } finally {
      this.updateProject(projectId, {
        operation: null,
      });
    }
  }

  async openInDesktop(
    projectId: string,
    events: {
      reopenProject: () => Promise<void>;
      flushProject: () => Promise<void>;
    },
  ): Promise<void> {
    if (!canPlatformOpenInDesktop()) {
      return;
    }
    try {
      const project = this.getProjectInternal(projectId);
      this.updateProject(projectId, {
        operation: CONNECTION_STATUS.SAVING,
      });
      await events.flushProject?.();
      await this.flushProject(projectId);
      await this.checkInProject(projectId);
      DialogClient.openInDesktop(() => {
        return this.reopenProject(projectId, events);
      });
      const params = await this.getOpenInDesktopParams(project);
      // fix this type once v2 is stable
      const fileScheme = getProjectDesktopFileScheme(
        project as unknown as CloudStorageProject,
      );
      downloadFileUrl(`${fileScheme}:${params}`, '');
    } finally {
      this.updateProject(projectId, {
        operation: null,
      });
    }
  }

  async checkInProject(projectId: string): Promise<boolean> {
    const project = this.getProjectInternal(projectId);

    const checkedIn = await CloudStorageApi.checkInProject(projectId);

    this.updateProject(projectId, {
      connection: {
        cloudStatus: checkedIn
          ? CLOUD_STATUS.READONLY
          : project.connection.cloudStatus,
      },
    });

    return checkedIn;
  }

  private async getOpenInDesktopParams(project: StoreProject): Promise<string> {
    const userEmail = ProviderStore.getProvider(project.connection.type)
      ?.account.email;
    let params: Record<string, string> = {};

    switch (project.connection.type) {
      case STORAGE_PROVIDER_KEYS.ONE_DRIVE:
        params = {
          type     : project.connection.type,
          id       : project.connection.itemId ?? '',
          drive    : project.connection.driveId ?? '',
          loginHint: userEmail ?? '',
        };
        break;
      case STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE:
        const providerItemInfo = await ProviderClient.getItem(
          project.connection,
        );
        if (!providerItemInfo) {
          throw new Error('Item not found.');
        }
        params = {
          name      : providerItemInfo.name,
          login_hint: userEmail ?? '',
          parentId  : providerItemInfo.parentReference.id,
          type      : project.connection.type,
          id        : project.connection.itemId ?? '',
        };
        break;
      default:
        break;
    }

    let paramStr = new URLSearchParams(params).toString();
    paramStr = paramStr.replace(/\+/g, '%20'); // MSSO requires the + instead
    return paramStr;
  }

  getProviderProjects(provider: string): string[] {
    const projects = Object.values(ProjectStore.getProjects());
    return projects.reduce((acc, project) => {
      if (project.connection.type === provider) {
        acc.push(project.id);
      }
      return acc;
    }, [] as string[]);
  }
}

const projectService = new ProjectService();

export default projectService;
