import type { DataCreateOptions, SelectOptions } from './types';
import type { DataItem } from '../../../clients/data/types';
import type { StorageProviderItem } from '@mtb/cloud-storage/types';
import { STORAGE_PROVIDER_KEYS, i18n as cloudStoragei18n } from '@mtb/cloud-storage';
import PlatformCore from '../..';
import CloudStorageClient from '../../../clients/cloud-storage';
import DataClient from '../../../clients/data';
import { decodeDataRef } from '../../../clients/data/utils';
import DialogsClient from '../../../clients/dialogs';
import I18nClient from '../../../clients/i18n';
import LoggerClient from '../../../clients/logger';
import ModuleClient from '../../../clients/module';
import MODULES from '../../../modules';
import platformStore from '../../store';
import { getModuleOptions } from './utils';

class DataService {
  #logger = LoggerClient.createNamedLogger('DataService');

  /**
   * Creates a data item from a string.
   * @param item - The string to create the data item from.
   * @param dataCreateOptions - Options for showing snackbars and dialogs
   * @returns - The created data item.
   */
  async #createFromString(item: string, dataCreateOptions?: DataCreateOptions): Promise<DataItem> {
    try {
      const dataRef = decodeDataRef(item);
      const nameParts = CloudStorageClient.getNameParts(dataRef.name);
      const options = {
        moduleKey: MODULES.PLATFORM.key,
        filter   : [`.${nameParts.extension}`],
      };
      if (dataRef.connection.type === STORAGE_PROVIDER_KEYS.LOCAL) {
        if (dataCreateOptions?.reselectData) {
          const doReset = await DialogsClient.showConfirmCloseDialog({
            title  : I18nClient.t('dataFileNotFoundTitle'),
            message: I18nClient.t('dataFileNotFound', { name: dataCreateOptions?.dataName ?? dataRef.name }),
            confirm: I18nClient.t('browse'),
          });
          if (doReset) {
            const item = await this.select(options);
            if (item) {
              return item;
            }
          }
          throw new Error('Failed to get data item from connection.');
        } else {
          const item = await this.select(options);
          if (!item) {
            throw new Error('Failed to get data item from connection.');
          }

          return item;
        }
      }
      const valid = await CloudStorageClient.validateProvider(dataRef.connection.type);
      if (!valid) {
        if (dataCreateOptions?.showProviderUnavailable) {
          const providerKey = {
            [STORAGE_PROVIDER_KEYS.ONE_DRIVE]   : 'oneDrive',
            [STORAGE_PROVIDER_KEYS.GOOGLE_DRIVE]: 'googleDrive',
          };
          PlatformCore.Snackbar.showSnackbar({
            title       : cloudStoragei18n.t('connection.notSignedIn'),
            anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
            content     : cloudStoragei18n.t('connection.accessRequired', {
              provider: cloudStoragei18n.t(`connection.${providerKey[dataRef.connection.type]}`),
            }),
            severity        : 'warning',
            autoHideDuration: 5000,
          });
        }
        throw new Error('Valid token for provider unavailable');
      }
      const storageItem = await CloudStorageClient.getItemById(dataRef.connection).catch(() => null);
      if (!storageItem) {
        if (dataCreateOptions?.reselectData) {
          const doReset = await DialogsClient.showConfirmCloseDialog({
            title  : I18nClient.t('dataFileNotFoundTitle'),
            message: I18nClient.t('dataFileNotFound', { name: dataCreateOptions?.dataName ?? dataRef.name }),
            confirm: I18nClient.t('browse'),
          });
          if (doReset) {
            const item = await this.select(options);
            if (item) {
              return item;
            }
          }
        }
        throw new Error('Failed to get data item from connection.');
      }
      return DataClient.create(storageItem);
    } catch (error) {
      throw new Error('Failed to create data item from string.');
    }
  }

  /**
   * Create a data item from the given item.
   * @param options - The options to create the data item with.
   * @param dataCreateOptions - Options for showing snackbars and dialogs
   * @returns - The created data item.
   */
  async create(
    item: string | File | StorageProviderItem,
    dataCreateOptions: DataCreateOptions = {
      showProviderUnavailable: true,
      reselectData           : true,
    },
  ): Promise<DataItem> {
    // If we receive a string, we need to a bit of detective work to figure out what to do with it
    // before we can call the `create` method on the DataClient.
    if (typeof item === 'string') {
      return await this.#createFromString(item, dataCreateOptions);
    }
    return await DataClient.create(item);
  }

  /**
   * Create a data item from the given passthrough id and name.
   * @param id - The passthrough id.
   * @param name - The passthrough name.
   * @returns - The created data item.
   */
  createFromPassthrough(id: string, name: string): DataItem {
    return DataClient.createFromPassthrough(id, name);
  }

  /**
   * Opens the Storage Explorer dialog and returns the selected item as a DataRef.
   * @param options
   * @returns The selected item as a DataRef.
   */
  async select(options: SelectOptions): Promise<DataItem | undefined> {
    try {
      const user = platformStore.selectors.user(platformStore.getState());
      const module = ModuleClient.getEnabledModule(options.moduleKey, user);
      if (!module) {
        throw new Error(`Module with key ${options.moduleKey} is not enabled.`);
      }

      const moduleOptions = getModuleOptions(module, options);
      const item = await DialogsClient.showStorageExplorerDialog({ defaultFilter: moduleOptions.filter ?? [] });
      if (!item) {
        return;
      }

      const data = this.create(item);
      return data;
    } catch (error) {
      this.#logger.error(error);
    }
  }
}

export default DataService;
