/* prettier and eslint seem to want to fight over the line length of the modules with the .filter call */
/* eslint-disable max-len */
import type { ModuleConfig } from '../../types';
import config from '../../config';
import { MODULES } from '../../modules';

type ExtensionModuleMap = {
  [key: string]: {
    defaultModule: ModuleConfig;
    modules: ModuleConfig[];
  };
};

/**
 * Map of file extensions and the respective module interactions
 * for creating new projects or providing open in options.
 */
export const EXTENSION_MODULE_MAP: ExtensionModuleMap = {
  '.txt': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.DATACENTER, MODULES.MSSO, config.feature_flag_dc_preview && MODULES.DASHBOARD].filter(m => !!m),
  },
  '.csv': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.DATACENTER, MODULES.MSSO, config.feature_flag_dc_preview && MODULES.DASHBOARD].filter(m => !!m),
  },
  '.dat': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.DATACENTER, MODULES.MSSO, config.feature_flag_dc_preview && MODULES.DASHBOARD].filter(m => !!m),
  },
  '.xlsx': {
    defaultModule: MODULES.MSSO,
    // we can't add dashboard to xlsx yet because datacenter can't export multiple mwx's from a single xlsx yet
    modules      : [MODULES.DATACENTER, MODULES.MSSO, config.feature_flag_dc_preview && config.feature_flag_dashboard_excel && MODULES.DASHBOARD].filter(m => !!m),
  },
  '.mtw': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO, MODULES.DATACENTER, MODULES.DASHBOARD],
  },
  '.mpj': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO],
  },
  '.mwx': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO, MODULES.DATACENTER, MODULES.DASHBOARD],
  },
  '.mpx': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO],
  },
  '.mac': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO],
  },
  '.mtb': {
    defaultModule: MODULES.MSSO,
    modules      : [MODULES.MSSO],
  },
  '.mbpx': {
    defaultModule: MODULES.BRAINSTORM,
    modules      : [MODULES.BRAINSTORM],
  },
  '.qcpx': {
    defaultModule: MODULES.WSO,
    modules      : [MODULES.WSO],
  },
  '.qctx': {
    defaultModule: MODULES.WSO,
    modules      : [MODULES.WSO],
  },
  '.wspx': {
    defaultModule: MODULES.WSO,
    modules      : [MODULES.WSO],
  },
  '.wstx': {
    defaultModule: MODULES.WSO,
    modules      : [MODULES.WSO],
  },
  '.mdbx': {
    defaultModule: MODULES.DASHBOARD,
    modules      : [MODULES.DASHBOARD],
  },
};
