import type { StoreProjectConnectionInfo } from '../../store/projects/types';
import { useCallback } from 'react';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Spacer,
  Stack,
  Tooltip,
} from '@mtb/ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LoadingButton } from '../../components';
import { useTranslation } from '../../services/i18n';
import { AutocompletePeople } from './AutocompleteEmails';
import { ShareDialogProvider, useShareFileDialog } from './context';
import { CopyAlert } from './CopyAlert/component';
import { CopyLinkButton } from './CopyLinkButton/component';
import { PermissionSelector } from './PermissionSelector';

const ShareFileDialogContent = () => {
  const [t] = useTranslation();
  const {
    fileName,
    selectedEmails,
    allEmailsValid,
    emailInputRef,
    isSharing,
    shareMutation,
    onClose,
  } = useShareFileDialog();

  const handleOnCancel = useCallback(() => onClose(false), [onClose]);

  const handleOnConfirm = useCallback(
    async () => {
      if (!allEmailsValid) {
        emailInputRef.current?.focus();
        return;
      }

      shareMutation.mutate();
    },
    [allEmailsValid, emailInputRef, shareMutation],
  );

  return (
    // @ts-expect-error-next-line: Bad MtbUI Types
    <Dialog
      data-testid="share-file-dialog"
      fullWidth
      maxWidth="sm"
      open={true}
      onClose={handleOnCancel}>
      {/* @ts-expect-error-next-line: Bad MtbUI Types */}
      <DialogTitle>
        {t('connection.shareSpecificFile', { fileName })}
        {/* @ts-expect-error-next-line: Bad MtbUI Types */}
        <Spacer />
        {/* @ts-expect-error-next-line: Bad MtbUI Types */}
        <Tooltip
          placement="bottom"
          title={t('connection.close')}>
          {/* @ts-expect-error-next-line: Bad MtbUI Types */}
          <Button
            color="default"
            // @ts-expect-error-next-line: Bad MtbUI Types
            icon={<CloseIcon />}
            variant="text"
            onClick={handleOnCancel} />
        </Tooltip>
      </DialogTitle>
      {/* @ts-expect-error-next-line: Bad MtbUI Types */}
      <DialogContent>
        <Stack gap={1}>
          <AutocompletePeople />
          <PermissionSelector />
        </Stack>
      </DialogContent>
      {/* @ts-expect-error-next-line: Bad MtbUI Types */}
      <DialogActions>
        <Stack
          gap={1}
          sx={{ width: '100%' }}>
          <Stack
            direction="row"
            gap={1}
            sx={{ width: '100%' }}>
            <CopyLinkButton />
            {/* @ts-expect-error-next-line: Bad MtbUI Types */}
            <Spacer />
            <LoadingButton
              disabled={!selectedEmails.length}
              loading={isSharing}
              variant="contained"
              onClick={handleOnConfirm}>
              {t('connection.send')}
            </LoadingButton>
            {/* @ts-expect-error-next-line: Bad MtbUI Types */}
            <Button
              color="default"
              variant="contained"
              onClick={handleOnCancel}>
              {t('shared.cancel')}
            </Button>
          </Stack>
          <CopyAlert />
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const queryClient = new QueryClient();

type ShareFileDialogProps = {
  fileName: string;
  connection: StoreProjectConnectionInfo;
  onClose: (confirmed: boolean) => void;
};

export const ShareFileDialog = (props: ShareFileDialogProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ShareDialogProvider {...props}>
        <ShareFileDialogContent />
      </ShareDialogProvider>
    </QueryClientProvider>
  );
};

export default ShareFileDialog;
