import type { StorageProviderSharingPermissions } from '../../../../types';
import { useCallback } from 'react';
import { MenuItem, TextField } from '@mtb/ui';
import { useTranslation } from '../../../services/i18n';
import { useShareFileDialog } from '../context';

const PERMISSIONS: { label: string, value: StorageProviderSharingPermissions }[] = [
  { label: 'connection.viewer', value: 'read' },
  { label: 'connection.editor', value: 'write' },
];

export const PermissionSelector = () => {
  const [t] = useTranslation();
  const { permission, setPermission } = useShareFileDialog();

  const handleOnChange = useCallback(
    (_e: unknown, { props: { value: newPermission } }: { props: { value: StorageProviderSharingPermissions } }) => {
      setPermission(newPermission);
    },
    [setPermission],
  );

  return (
    <TextField
      label={t('connection.permission')}
      // @ts-expect-error-next-line: Bad MtbUI Types
      select
      sx={{ width: '25ch' }}
      value={permission}
      // @ts-expect-error-next-line: Bad MtbUI Types
      onChange={handleOnChange}>
      {/* @ts-expect-error-next-line: Bad MtbUI Types */}
      {PERMISSIONS.map(option => (
        // @ts-expect-error-next-line: Bad MtbUI Types
        <MenuItem
          key={option.value}
          value={option.value}>
          {t(option.label)}
        </MenuItem>
      ))}
    </TextField>
  );
};
