import type { ModuleConfigKey } from '../../modules/types';
import LoggerClient from '../logger';
import DataSyncActionHandler from './data-sync-actions-handler';
import ModuleQueue from './queue';

class ModuleActionManager {
  #logger = LoggerClient.createNamedLogger('ModuleActionManager');

  invokeModuleMethod(moduleKey: ModuleConfigKey, methodName: string, ...args: unknown[]): Promise<unknown> {
    return ModuleQueue.fireOrQueue<unknown>(moduleKey, async config => {
      this.#logger.log(`Firing invokeModuleMethod action with method name: ${methodName}`);

      return config.actions?.invokeModuleMethod(moduleKey, methodName, ...args);
    });
  }

  get DataSync() {
    return DataSyncActionHandler;
  }
}

const moduleActionManager = new ModuleActionManager();

export default moduleActionManager;
