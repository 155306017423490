import type { AsyncPlaneThunkState } from '../types';
import { createAsyncThunk } from '../../../redux-helpers/create-async-thunk';
import { closeStaleRelays } from '../../relays/close-stale-relays';
import { initializePlane } from '../initialize-plane';

export const initializePlanes = createAsyncThunk.withTypes<AsyncPlaneThunkState>()(
  'planes/initializePlanes',
  async (_, { dispatch, getState }) => {
    const planes = Object.values(getState().planes);
    const stalePlaneIds = planes.map(plane => plane.id);

    // @ts-expect-error - We need to fix our types
    dispatch(closeStaleRelays(stalePlaneIds));

    await Promise.all(planes.map(plane => dispatch(initializePlane(plane.id))));
  },
);
