import type { SetInteractiveAction } from './types';
import type { WritableLayoutState } from '../types';

export const setInteractive = {
  reducer: (state: WritableLayoutState, action: SetInteractiveAction) => {
    state.interactive = action.payload.interactive;
  },
  prepare: (interactive: boolean) => {
    return { payload: { interactive } };
  },
};
