import type { ModuleConfigKey } from '../../modules/types';
import type { RemoteModuleConfig } from '../../types';
import { waitForCondition } from '@mtb/utilities';
import ModuleConfigClient from './config';

class ModuleQueue {
  #WAIT_FOR_MODULE_TIMEOUT = 60 * 1000;
  #callbackQueue: Map<ModuleConfigKey, Array<() => Promise<unknown>>>;

  constructor() {
    this.#callbackQueue = new Map();
  }

  fireOrQueue<T>(moduleKey: ModuleConfigKey, callback: (ModuleConfig: RemoteModuleConfig) => Promise<T>): Promise<T> {
    const moduleConfig = ModuleConfigClient.getRemoteModuleConfig(moduleKey);
    if (moduleConfig) {
      return callback(moduleConfig);
    }

    return this.getQueuedCallback<T>(moduleKey, async (resolve, reject) => {
      try {
        const moduleConfig = ModuleConfigClient.getRemoteModuleConfig(moduleKey);
        if (!moduleConfig) {
          throw new Error('Failed to fetch ModuleConfig');
        }
        const result = await callback(moduleConfig);
        resolve(result);
      } catch (e) {
        reject(e);
      }
    });
  }

  async flush(moduleKey: ModuleConfigKey): Promise<void> {
    const moduleCallbacks = this.#callbackQueue.get(moduleKey);
    while (moduleCallbacks?.length) {
      await moduleCallbacks.shift()?.();
    }
  }

  async getQueuedCallback<T>(
    moduleKey: ModuleConfigKey,
    callback: (res: (result: T) => void, rej: (reason: unknown) => void) => Promise<unknown>,
  ): Promise<T> {
    let resolve: (result: T) => void;
    let reject: (reason: unknown) => void;
    const wrappedCallback = () => callback(resolve, reject);

    const callbackPromise = new Promise<T>(async (res, rej) => {
      resolve = res;
      reject = rej;

      let config: RemoteModuleConfig | undefined;
      const getConfig = () => config = ModuleConfigClient.getRemoteModuleConfig(moduleKey);
      const moduleReady = () => Boolean(config || getConfig());

      if (!moduleReady()) {
        await waitForCondition(moduleReady, this.#WAIT_FOR_MODULE_TIMEOUT);
      }

      if (!config) {
        rej(new Error('Timed Out'));
      }
    });

    const moduleQueue = this.#callbackQueue.get(moduleKey);
    if (!moduleQueue) {
      this.#callbackQueue.set(moduleKey, [wrappedCallback]);
    } else {
      moduleQueue.push(wrappedCallback);
    }

    return await callbackPromise;
  }
}

const moduleQueue = new ModuleQueue();

export default moduleQueue;
