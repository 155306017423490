import { useCallback } from 'react';
import {
  Button,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogIcon,
  DialogTitle,
  Spacer,
  Stack,
  Tooltip,
} from '@mtb/ui';
import { useTranslation } from '../../services/i18n';

export const InvalidFileTypeDialog = ({ open, onClose }) => {
  const [t] = useTranslation('connection');

  const handleOnClose = useCallback(
    (confirm) => (event) => {
      event.stopPropagation();
      onClose(confirm);
    },
    [onClose],
  );

  return (
    <Dialog
      data-testid="invalid-file-type-dialog"
      open={open}
      onClose={handleOnClose(false)}>
      <Stack direction="row">
        <DialogIcon severity="error" />
        <Stack
          direction="column"
          width="100%">
          <DialogTitle>
            {t('connection.invalidFileType')}
            <Spacer />
            <Tooltip
              placement="bottom"
              title={t('connection.close')}>
              <Button
                aria-label={t('connection.close')}
                color="default"
                icon={<CloseIcon />}
                variant="text"
                onClick={handleOnClose(true)} />
            </Tooltip>
          </DialogTitle>
          <DialogContent>{t('connection.invalidFileMessage')}</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={handleOnClose(true)}>
              {t('shared.OK')}
            </Button>
          </DialogActions>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default InvalidFileTypeDialog;
